// import React, { useRef } from "react";
// import { saveAs } from "file-saver";

// function Table() {
//   const tableRef = useRef(null);

//   function generateCSV(table) {
//     const rows = Array.from(table.current.rows);
//     const csvContent = rows
//       .map((row) =>
//         Array.from(row.cells)
//           .map((cell) => `"${cell.innerText}"`)
//           .join(",")
//       )
//       .join("\n");
//     return csvContent;
//   }

//   function downloadTableData(tableRef) {
//     const csvContent = generateCSV(tableRef);

//     const element = document.createElement("a");
//     const file = new Blob([csvContent], { type: "text/csv" });
//     element.href = URL.createObjectURL(file);
//     element.download = "tableData.csv";
//     document.body.appendChild(element);
//     element.click();
//     document.body.removeChild(element);
//   }

//   return (
//     <div>
//   <table ref={tableRef} className="w-full text-sm text-left text-gray-400 ">
//     <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
//       <tr className="bg-gray-500 text-white">
//         <th scope="col" className="px-2 py-3 w-[20%] text-center">
//           # Name
//         </th>
//         <th scope="col" className="px-2 py-3 w-[20%] text-center">
//           # Date
//         </th>

//         {/* <th scope="col" className="px-6 py-3">
//     CODE
//   </th> */}
//         <th scope="col" className="px-2 py-3 w-[20%] text-center">
//           # Punch In
//         </th>
//         <th scope="col" className="px-2 py-3 w-[20%] text-center">
//           # Punch Out
//         </th>
//         <th scope="col" className="px-2 py-3 w-[20%] text-center">
//           # Attendance
//         </th>
//       </tr>
//     </thead>
//     <tbody>
//       <tr
//         // key={index}
//         className={`  border-b border-gray-100 text-black`}
//       >
//         <td className="px-2 py-2   text-center">Hi</td>
//         <td className="px-2 py-2 text-center">H</td>
//         <td className="px-2 py-2  text-center">Hii</td>

//         <td className="px-2 py-2   text-center">Hiii</td>
//         <td className="px-2 py-2   text-center">Hiiii</td>
//       </tr>

//       <tr
//         // key={index}
//         className={`  border-b border-gray-100 text-black`}
//       >
//         <td className="px-2 py-2   text-center">Ji</td>
//         <td className="px-2 py-2 text-center">J</td>
//         <td className="px-2 py-2  text-center">Jii</td>

//         <td className="px-2 py-2   text-center">Jiii</td>
//         <td className="px-2 py-2   text-center">Jiiii</td>
//       </tr>
//     </tbody>
//   </table>
//       <button onClick={() => downloadTableData(tableRef)}>
//         Download Table Data
//       </button>
//     </div>
//   );
// }

// export default Table;

// import React from "react";
// // import xlsx from "xlsx";
// import { saveAs } from "file-saver";

// function downloadTableData() {
//   const xlsx = require("xlsx");

//   const workbook = xlsx.utils.book_new();
//   const worksheet = xlsx.utils.table_to_sheet(
//     document.getElementById("tableId")
//   );
//   xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
//   const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
//   const data = new Blob([excelBuffer], {
//     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   });
//   saveAs(data, "tableData.xlsx");
// }

// function Table() {
//   return (
//     <div>
//       <table
//         id="tableId"
//         // ref={tableRef}
//         className="w-full text-sm text-left text-gray-400 "
//       >
//         <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
//           <tr className="bg-gray-500 text-white">
//             <th scope="col" className="px-2 py-3 w-[20%] text-center">
//               # Name
//             </th>
//             <th scope="col" className="px-2 py-3 w-[20%] text-center">
//               # Date
//             </th>

//             {/* <th scope="col" className="px-6 py-3">
//         CODE
//       </th> */}
//             <th scope="col" className="px-2 py-3 w-[20%] text-center">
//               # Punch In
//             </th>
//             <th scope="col" className="px-2 py-3 w-[20%] text-center">
//               # Punch Out
//             </th>
//             <th scope="col" className="px-2 py-3 w-[20%] text-center">
//               # Attendance
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr
//             // key={index}
//             className={`  border-b border-gray-100 text-black`}
//           >
//             <td className="px-2 py-2   text-center">Hi</td>
//             <td className="px-2 py-2 text-center">H</td>
//             <td className="px-2 py-2  text-center">Hii</td>

//             <td className="px-2 py-2   text-center">Hiii</td>
//             <td className="px-2 py-2   text-center">Hiiii</td>
//           </tr>

//           <tr
//             // key={index}
//             className={`  border-b border-gray-100 text-black`}
//           >
//             <td className="px-2 py-2   text-center">Ji</td>
//             <td className="px-2 py-2 text-center">J</td>
//             <td className="px-2 py-2  text-center">Jii</td>

//             <td className="px-2 py-2   text-center">Jiii</td>
//             <td className="px-2 py-2   text-center">Jiiii</td>
//           </tr>
//         </tbody>
//       </table>
//       <button onClick={downloadTableData}>Download Table Data</button>
//     </div>
//   );
// }

// export default Table;

import React from "react";
// import XLSX from "xlsx";

import { saveAs } from "file-saver";

function Table() {
  const xlsx = require("xlsx");

  function generateWorksheet(table) {
    const worksheet = xlsx.utils.aoa_to_sheet([]);
    const rows = table.querySelectorAll("tr");
    const workbook = xlsx.utils.book_new();
    let rowIndex = 0;
    rows.forEach((row) => {
      const cells = row.querySelectorAll("th, td");
      const rowData = [];

      cells.forEach((cell) => {
        if (cell.querySelector("table")) {
          const nestedTable = cell.querySelector("table");
          const nestedWorksheet = generateWorksheet(nestedTable);
          xlsx.utils.book_append_sheet(
            workbook,
            nestedWorksheet,
            `NestedTable-${rowIndex}-${rowData.length}`
          );
          rowData.push(`NestedTable-${rowIndex}-${rowData.length}`);
        } else {
          rowData.push(cell.innerText);
        }
      });

      xlsx.utils.sheet_add_aoa(worksheet, [rowData], { origin: -1 });
      rowIndex++;
    });

    return worksheet;
  }

  function downloadTableData() {
    const workbook = xlsx.utils.book_new();
    const table = document.getElementById("parentTable");
    const worksheet = generateWorksheet(table);
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "tableData.xlsx");
  }
  return (
    <div>
      <table id="parentTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Age</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>John Doe</td>
            <td>30</td>
            <td>john@example.com</td>
          </tr>
          <tr>
            <td>Jane Smith</td>
            <td>25</td>
            <td>jan@example.com</td>
          </tr>
          <tr>
            <td>Parent Table with Nested Table</td>
            <td colSpan={2}>
              <table>
                <thead>
                  <tr>
                    <th>Nested Column 1</th>
                    <th>Nested Column 2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nested Cell 1</td>
                    <td>Nested Cell 2</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <button onClick={downloadTableData}>Download Table Data</button>
    </div>
  );
}

export default Table;
