import { Route, Routes } from "react-router-dom";
// import Sidebar from "./component/sidebar/Sidebar";
import Dashboard from "./component/dashboard/Dashboard";
import Report from "./component/request/Report";
// import Login from "./component/login/Login";
import Upload from "./component/upload/Upload";
// import Navbar from "./component/navbar/Navbar";
import Loginpage from "./component/login/Loginpage";
import Table from "./component/request/Table";
import Target from "./component/target/Target";
// import Demo from "./component/request/Demo";
// import Drag from "./component/upload/Drag";

function App() {
  return (
    <>
      <Routes>
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/" element={<Loginpage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/sidebar" element={<Sidebar />} /> */}
        <Route path="/report" element={<Report />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/target" element={<Target />} />
        {/* <Route path="/navbar" element={<Navbar />} /> */}
        {/* <Route path="/demo" element={<Demo />} /> */}
        {/* <Route path="/drag" element={<Drag />} /> */}
        <Route path="/table" element={<Table />} />
      </Routes>
    </>
  );
}

export default App;
