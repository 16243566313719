import React from "react";
import stanvacLogo from "../../assets/stanvaclogo.png";
import { NavLink, useNavigate } from "react-router-dom";

import "./navbar.css";

function Navbar() {
  let adminEmail = localStorage.getItem("superonAdmin");

  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.removeItem("superonAdmin");
    navigate("/");
  };

  return (
    <div>
      <main className="h-[11vh] bg-blue-light">
        <nav className="flex justify-between items-center h-full px-11">
          <div>
            <img src={stanvacLogo} alt="stanvac-logo" className="w-28" />
          </div>
          <ul className="flex gap-10 text-white text-lg">
            <li>
              <NavLink to="/dashboard" activeClassName="active">
                All User
              </NavLink>
            </li>
            <li>
              <NavLink to="/report" activeClassName="active">
                Report
              </NavLink>
            </li>
            <li>
              <NavLink to="/target" activeClassName="active">
                Target
              </NavLink>
            </li>
            <li>
              <NavLink to="/upload" activeClassName="active">
                Upload
              </NavLink>
            </li>
            <li title={adminEmail} onClick={() => handleLogOut()}>
              <NavLink className={"inactive"}>Logout</NavLink>
            </li>
          </ul>
        </nav>
      </main>
    </div>
  );
}

export default Navbar;
