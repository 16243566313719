import React, { useCallback, useEffect, useRef, useState } from "react";
import Datepicker from "react-datepicker";
import Select from "react-select";
import Sidebar from "../sidebar/Sidebar";
import downloadIcon from "../../assets/download-icon.png";
import printIcon from "../../assets/print-icon.png";
import downloadLogo from "../../assets/downloadIcon.svg";
import submitLogo from "../../assets/icon-submit.svg";
import cancelLogo from "../../assets/cross.svg";
import downArrow from "../../assets/down-arrow.svg";
import upArrow from "../../assets/up-arrow.svg";
import crossIcon from "../../assets/cross-small.svg";
import loadingIcon from "../../assets/spinner.gif";
// import XLSX from "xlsx";
import { saveAs } from "file-saver";

import "react-datepicker/dist/react-datepicker.css";
import "./request.css";
import Navbar from "../navbar/Navbar";
import axios from "axios";
import config from "../utils/config";
import { useNavigate } from "react-router-dom";
import loadingGif from "../../assets/loading2.gif";

function Report() {
  let adminEmail = localStorage.getItem("superonAdmin");

  const navigate = useNavigate();

  const [selectedDateStart, setSelectedDateStart] = useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const [allUserCopy, setAllUserCopy] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [employeeName, setEmployeeName] = useState("");
  const [employeeMobile, setEmployeeMobile] = useState("");
  const [divWidth, setDivWidth] = useState(null);
  const divRef = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [downloadList, setDownloadList] = useState([]);

  //
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const [employeeDarList, setEmployeeDarList] = useState([]);
  const [employeeOdometerList, setEmployeeOdometerList] = useState([]);
  const [employeeLeaveList, setEmployeeLeaveList] = useState([]);
  const [employeeSampleList, setEmployeeList] = useState([]);
  const [employeeLedgerList, setEmployeeLedgerList] = useState([]);
  //
  const [employeeNames, setEmployeeNames] = useState([]);
  const [employeePhNum, setEmployeePhNum] = useState([]);

  const [isattendanceChecked, setIsAttendanceChecked] = useState(true);
  const [isDarChecked, setIsDarChecked] = useState(false);
  const [isImprestChecked, setIsImprestChecked] = useState(false);
  const [isLeaveChecked, setIsLeaveChecked] = useState(false);
  const [isSampleChecked, setIsSampleChecked] = useState(false);
  const [isLedgerChecked, setIsLedgerChecked] = useState(false);

  const [tableId, setTableId] = useState(1);
  const [downloadName, setDownloadName] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);

  // handle user
  useEffect(() => {
    let isValidate = adminEmail;
    if (!isValidate) {
      navigate("/");
    }
  }, []);

  // table
  const [showTableRow, setShowTableRow] = useState(false);

  // const getAllEmployee = useCallback(async () => {
  //   let data = {
  //     email: adminEmail,
  //   };
  //   let response = await axios.post(config.ipAddress + `/employees`, data);

  //   console.log(response?.data?.data);
  //   let allEmployeeList = response?.data?.data;
  //   let filteredEmployeeList = allEmployeeList.filter((employee) => {
  //     return employee.name !== null;
  //   });
  //   console.log(filteredEmployeeList);
  //   setAllUser(filteredEmployeeList);
  //   setAllUserCopy(filteredEmployeeList);

  //   for (let i = 0; i < filteredEmployeeList.length; i++) {
  //     let newObject = {
  //       value: filteredEmployeeList[i].mobile,
  //       label: filteredEmployeeList[i].name,
  //     };

  //     setOptions((prevArray) => [...prevArray, newObject]);
  //   }
  // }, []);

  function formatName(name) {
    if (!name) return;
    const words = name.toLowerCase().split(" ");

    const formattedWords = words.map((word) => {
      if (word.length > 1) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word.toUpperCase();
      }
    });

    return formattedWords.join(" ");
  }

  const getAllEmployee = useCallback(async () => {
    let data = {
      email: adminEmail,
    };
    let response = await axios.post(config.ipAddress + `/employees`, data);

    console.log(response?.data?.data);
    let allEmployeeList = response?.data?.data;

    let uniqueEmployeeNames = new Set(); // Use a Set to store unique names
    let filteredEmployeeList = allEmployeeList.filter((employee) => {
      if (employee.name !== null && !uniqueEmployeeNames.has(employee.name)) {
        uniqueEmployeeNames.add(employee.name);
        return true;
      }
      return false;
    });

    console.log(filteredEmployeeList);
    setAllUser(filteredEmployeeList);
    setAllUserCopy(filteredEmployeeList);

    let optionsArray = filteredEmployeeList.map((employee) => ({
      value: employee.mobile,
      label: formatName(employee.name),
    }));
    setOptions(optionsArray);
  }, []);

  const handleEmployeeChange = (e) => {
    setEmployeeName(e.target.value);

    let filteredList = allUserCopy.filter((item, index) => {
      // console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());
      return item.name
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase());
    });

    console.log(filteredList);
    setAllUser(filteredList);
  };

  const handleEmployeeName = (dealerName, mobile) => {
    // let namesArr = [];
    // let phArr = [];
    // namesArr.push(dealerName);
    // phArr.push(mobile);

    setEmployeeNames([...employeeNames, dealerName]);
    setEmployeePhNum([...employeePhNum, mobile]);
    console.log(dealerName, mobile);
    setEmployeeName(dealerName);
    setEmployeeMobile(mobile);
    setShowEmployeeList(false);
  };

  const handleRemoveName = (indexOfName) => {
    let filteredNames = employeeNames.filter((name, index) => {
      return indexOfName !== index;
    });

    setEmployeeNames(filteredNames);
  };

  const covertDateString = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const outputString = `${year}-${month}-${day}`;

    console.log(outputString); // Output: "2023-4-6"

    return outputString;
  };
  const convertTime = (timeString) => {
    // const timeString = "11:32:32";
    if (!timeString) return null;
    const time = new Date(`2000-01-01T${timeString}`);
    const formattedTime = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    // console.log(formattedTime); // Output: 11:32 AM
    return formattedTime;
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    // console.log(formattedDate);

    return formattedDate;
  };

  const convertIntoCamelCase = (name) => {
    if (!name) return;
    let formattedName = name.charAt(0) + name.slice(1).toLowerCase();

    return formattedName;
  };

  // convert name
  function formatName(name) {
    if (!name) return;

    const words = name.toLowerCase().split(" ");

    const formattedWords = words.map((word) => {
      if (word.length > 1) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word.toUpperCase();
      }
    });

    return formattedWords.join(" ");
  }

  const convertDistance = (distance) => {
    // console.log("runninggggggggg");
    // console.log(distance);
    if (!distance) return 0;

    let distanceNum = Number(distance);
    return distanceNum.toFixed(2);
  };
  // const names = [
  //   'Kouhsik saha',
  //   'Koushik kumar saha',
  //   'kouhsik b. kumar saha'
  // ];

  // const formattedNames = names.map((name) => formatName(name));
  // console.log(formattedNames);

  // handleAllClick 🚀🚀🚀
  const handleClick = () => {
    // if (isattendanceChecked) {
    //   getEmployeeDetails();
    //   return;
    // }

    // if (isDarChecked) {
    //   getEmployeeDarDetails();
    //   return;
    // }

    // if (isImprestChecked) {
    //   getEmployeeOdometerList();
    //   return;
    // }

    // if (isLeaveChecked) {
    //   getEmployeeLeaveList();
    //   return;
    // }

    // if (isLedgerChecked) {
    //   getLedgerList();
    //   return;
    // }
    // if (isSampleChecked) {
    //   getEmployeeSampleList();
    //   return;
    // }
    // setShowLoading(true);
    getEmployeeDetails();
    getEmployeeDarDetails();
    getEmployeeOdometerList();
    getEmployeeLeaveList();
    getLedgerList();
    getEmployeeSampleList();
    // setShowLoading(false);
  };

  // Attendance 🚀🚀🚀
  const getEmployeeDetails = async () => {
    // if (!isattendanceChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");

    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      email: adminEmail,
    };
    // 9599227946,8619590275
    // let data = {
    //   mobile: "9599227946",
    //   fromdate: "2017-5-01",
    //   todate: "2023-5-18",
    // };
    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/attendance-search`,
        data
      );

      let allData = response?.data?.data;
      // allData.sort((a, b) => {
      //   const dateA = new Date(
      //     a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );
      //   const dateB = new Date(
      //     b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );

      //   if (dateA > dateB) {
      //     return -1;
      //   } else if (dateA < dateB) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });

      allData.sort((a, b) => {
        const dateA = new Date(
          a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        );
        const dateB = new Date(
          b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        );

        // First, compare by date
        if (dateA > dateB) {
          return -1;
        } else if (dateA < dateB) {
          return 1;
        } else {
          // If the dates are equal, compare by name
          if (a.name.toLowerCase().trim() > b.name.toLowerCase().trim()) {
            return 1;
          } else if (
            a.name.toLowerCase().trim() < b.name.toLowerCase().trim()
          ) {
            return -1;
          } else {
            return 0;
          }
        }
      });

      console.log(response?.data?.data);
      setSearchEmployeeList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(1);
      setDownloadName("Attendance");
    } catch (error) {
      console.log(error);
      setShowLoading(false);
    }
  };

  // DAR 🚀🚀🚀
  const getEmployeeDarDetails = async () => {
    // if (!isDarChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");
    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      email: adminEmail,
    };
    // 9599227946,8619590275
    // let data = {
    //   mobile: "9599227946",
    //   fromdate: "2017-5-01",
    //   todate: "2023-5-18",
    // };
    console.log(data);

    try {
      let response = await axios.post(config.ipAddress + `/dar-search`, data);
      let allData = response?.data?.data;
      allData.sort((a, b) => {
        const dateA = new Date(
          a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        );
        const dateB = new Date(
          b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        );

        // First, compare by date
        if (dateA > dateB) {
          return -1;
        } else if (dateA < dateB) {
          return 1;
        } else {
          // If the dates are equal, compare by name
          if (a.name.toLowerCase().trim() > b.name.toLowerCase().trim()) {
            return 1;
          } else if (
            a.name.toLowerCase().trim() < b.name.toLowerCase().trim()
          ) {
            return -1;
          } else {
            return 0;
          }
        }
      });

      console.log(response?.data?.data);
      // console.log(allData);
      setEmployeeDarList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(2);
      setDownloadName("Visit");
    } catch (error) {
      console.log(error);
      setShowLoading(false);
    }
  };

  // Odometer 🚀🚀🚀

  const getEmployeeOdometerList = async () => {
    // if (!isImprestChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");

    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      email: adminEmail,
    };

    try {
      let response = await axios.post(
        config.ipAddress + `/odometer-search-new`,
        data
      );

      let allData = response?.data?.data;
      // allData.sort((a, b) => {
      //   const dateA = new Date(
      //     a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );
      //   const dateB = new Date(
      //     b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );

      //   if (dateA > dateB) {
      //     return -1;
      //   } else if (dateA < dateB) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });
      console.log(response?.data?.data);
      // console.log(allData);
      setEmployeeOdometerList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(3);
      setDownloadName("Odometer");
    } catch (error) {
      console.log(error);
      setShowLoading(false);
    }
  };

  // odometer total reading
  const getOdoTotalReading = (punchIn, punchOut) => {
    if (punchIn === punchOut) return 0;
    let from = Number(punchIn);
    let to = Number(punchOut);
    return (to - from).toFixed(2);
  };

  const getPunchOutDetails = (
    punchInId,
    punchOutId,
    punchOutReading,
    punchOutImage,
    punchOutRemark
  ) => {
    let punchOutValue;
    let punchOutImageUrl;
    let punchOutRemarkUpdated;
    if (punchInId === punchOutId) {
      punchOutValue = "not created";
      punchOutImageUrl = "";
      punchOutRemarkUpdated = "not created";
      return { punchOutValue, punchOutImageUrl, punchOutRemarkUpdated };
    } else {
      punchOutValue = punchOutReading;
      punchOutImageUrl = config.ipAddress + punchOutImage;
      punchOutRemarkUpdated = punchOutRemark;
    }
    return { punchOutValue, punchOutImageUrl, punchOutRemarkUpdated };
  };

  const handleOdometerDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-GB");
    return formattedDate;
  };

  // Leave 🚀🚀🚀

  const getEmployeeLeaveList = async () => {
    // if (!isLeaveChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");

    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      email: adminEmail,
    };

    try {
      let response = await axios.post(config.ipAddress + `/leave-search`, data);

      let allData = response?.data?.data;
      // allData.sort((a, b) => {
      //   const dateA = new Date(
      //     a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );
      //   const dateB = new Date(
      //     b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );

      //   if (dateA > dateB) {
      //     return -1;
      //   } else if (dateA < dateB) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });
      console.log(response?.data?.data);
      // console.log(allData);
      setEmployeeLeaveList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(4);
      setDownloadName("Leave");
    } catch (error) {
      console.log(error);
      setShowLoading(false);
    }
  };

  // Sample/Catlogue 🚀🚀🚀

  const getEmployeeSampleList = async () => {
    // if (!isSampleChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");

    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      email: adminEmail,
    };

    try {
      let response = await axios.post(
        config.ipAddress + `/catalogue-search`,
        data
      );

      let allData = response?.data?.data;
      // allData.sort((a, b) => {
      //   const dateA = new Date(
      //     a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );
      //   const dateB = new Date(
      //     b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );

      //   if (dateA > dateB) {
      //     return -1;
      //   } else if (dateA < dateB) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });
      console.log(response?.data?.data);
      // console.log(allData);
      setEmployeeList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(5);
      setDownloadName("Sample");
    } catch (error) {
      console.log(error);
      setShowLoading(false);
    }
  };

  // ledger List 🚀🚀🚀🚀
  const getLedgerList = async () => {
    // if (!isLedgerChecked) return alert("Please check at least one box!");

    if (!(selectedDateStart && selectedDateEnd))
      return alert("Please enter all details!");

    setShowLoading(true);

    let phNumString = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        phNumString.push(selectedOption[i].value);
      }
    }
    // console.log(phNumString);

    let data = {
      mobile: phNumString.join(","),
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
      email: adminEmail,
    };

    try {
      let response = await axios.post(
        config.ipAddress + `/ledger-search`,
        data
      );

      let allData = response?.data?.data;
      // allData.sort((a, b) => {
      //   const dateA = new Date(
      //     a.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );
      //   const dateB = new Date(
      //     b.attendancedate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      //   );

      //   if (dateA > dateB) {
      //     return -1;
      //   } else if (dateA < dateB) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });
      console.log(response?.data?.data);
      // console.log(allData);
      setEmployeeLedgerList(allData);
      setDownloadList(allData);
      setShowLoading(false);
      setTableId(6);
      setDownloadName("Ledger");
    } catch (error) {
      console.log(error);
      setShowLoading(false);
    }
  };

  // Function to flatten nested array object data
  const flattenData = (data) => {
    const flattenedData = [];

    const flatten = (obj, prefix = "") => {
      for (let key in obj) {
        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          flatten(obj[key], `${prefix}${key}.`);
        } else if (Array.isArray(obj[key])) {
          obj[key].forEach((item, index) => {
            flatten(item, `${prefix}${key}[${index}].`);
          });
        } else {
          flattenedData.push({
            key: `${prefix}${key}`,
            value: obj[key],
          });
        }
      }
    };

    flatten(data);

    return flattenedData;
  };

  // Download Data 🚀🚀🚀
  // const downloadData = () => {
  //   if (!downloadList.length) return;

  //   const xlsx = require("xlsx");
  //   const workbook = xlsx.utils.book_new();

  //   let worksheet = xlsx.utils.json_to_sheet(downloadList.flat(10));
  //   xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   const excelFileBuffer = xlsx.write(workbook, {
  //     type: "buffer",
  //     bookType: "xlsx",
  //   });

  //   const blob = new Blob([excelFileBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = "employees_data.xlsx";
  //   link.click();
  // };

  // dar data download 🚀🚀🚀
  let tableToExcel = (function () {
    let uri = "data:application/vnd.ms-excel;base64,",
      template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };
    return function (table, name) {
      if (!table.nodeType) table = document.getElementById(table);
      let ctx = { worksheet: name || "Worksheet", table: table.innerHTML };
      window.location.href = uri + base64(format(template, ctx));
    };
  })();

  // downlaod table data 🚀🚀🚀🚀🚀🚀
  function downloadTableData(table) {
    if (isDarChecked) {
      tableToExcel(table);
      return;
    }
    const xlsx = require("xlsx");

    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.table_to_sheet(
      document.getElementById(`tableId${tableId}`)
    );
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `${downloadName}TableData.xlsx`);
  }

  // another dar data download
  const handleAttendanceChange = (e) => {
    setIsAttendanceChecked(e.target.checked);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
    setIsSampleChecked(false);
    setIsLedgerChecked(false);
  };
  const handleDarChange = (e) => {
    setIsDarChecked(e.target.checked);
    setIsAttendanceChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
    setIsSampleChecked(false);
    setIsLedgerChecked(false);
  };

  const handleImprestChange = (e) => {
    setIsImprestChecked(e.target.checked);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsLeaveChecked(false);
    setIsSampleChecked(false);
    setIsLedgerChecked(false);
  };

  const handleLeaveChange = (e) => {
    setIsLeaveChecked(e.target.checked);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsSampleChecked(false);
    setIsLedgerChecked(false);
  };

  const handleSampleChange = (e) => {
    setIsSampleChecked(e.target.checked);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
    setIsLedgerChecked(false);
  };

  const handleLedgerChange = (e) => {
    setIsLedgerChecked(e.target.checked);
    setIsSampleChecked(false);
    setIsAttendanceChecked(false);
    setIsDarChecked(false);
    setIsImprestChecked(false);
    setIsLeaveChecked(false);
  };

  useEffect(() => {
    getAllEmployee();
    // getEmployeeDetails();
  }, []);

  useEffect(() => {
    // handleClick();
    if (isattendanceChecked) {
      getEmployeeDetails();
      // setTableId(1);
      return;
    }

    if (isDarChecked) {
      getEmployeeDarDetails();
      // setTableId(2);

      return;
    }

    if (isImprestChecked) {
      getEmployeeOdometerList();
      // setTableId(3);

      return;
    }

    if (isLeaveChecked) {
      getEmployeeLeaveList();
      // setTableId(4);

      return;
    }

    if (isLedgerChecked) {
      getLedgerList();
      // setTableId(5);

      return;
    }
    if (isSampleChecked) {
      getEmployeeSampleList();
      // setTableId(6);

      return;
    }
  }, [
    selectedOption,
    isattendanceChecked,
    isDarChecked,
    isImprestChecked,
    isLeaveChecked,
    isSampleChecked,
    isLedgerChecked,
    selectedDateStart,
    selectedDateEnd,
  ]);

  console.log(selectedOption);

  return (
    <div className={`${showLoading ? "overflow-hidden" : ""}`}>
      <div className=" bg-[#fafafa]">
        {/* <div className="w-[18%]">
          <Sidebar />
        </div> */}
        <div className="sticky top-0 left-0 z-[3]">
          <Navbar />
        </div>
        <section className={`w-full bg-[#fff] px-10 pt-4 `}>
          <main
            className={`bg-white w-full shadow-6xl rounded-[10px] py-4 border-[1px] border-gray-300 `}
          >
            <div
              // onClick={() => setShowEmployeeList(false)}
              className="flex gap-2 mb-2 ml-4 "
            >
              <div className="bg-blue-light w-[2.5px] rounded-full"></div>
              <div className=" ">
                <h1 className="text-lg font-semibold">Add Request</h1>
              </div>
            </div>
            {/* <div className="flex justify-between mt-3 px-5 py-3 bg-[#EFF2F7]">
              <h1 className="font-semibold">Attendance / Punch In - Out</h1>
              <div className="flex gap-4">
                <div className="flex items-center  ">
                  <div className="bg-[#1BA4CF] px-2 py-2">
                    <img
                      src={downloadIcon}
                      alt="download-logo"
                      className="w-3"
                    />
                  </div>
                  <span className="text-sm text-white px-4 py-[4px] bg-[#27B7E4]">
                    Export
                  </span>
                </div>

                <div className="flex items-center  ">
                  <div className="bg-[#6358A3] px-2 py-2">
                    <img src={printIcon} alt="download-logo" className="w-3" />
                  </div>
                  <span className="text-sm text-white px-4 py-[4px] bg-[#7266BA]">
                    Print
                  </span>
                </div>
              </div>
            </div> */}

            <div className=" py-4 px-4 w-full flex gap-5 ">
              {/* <form
                action=""
                className="flex gap-x-5 w-full justify-between items-center"
              > */}
              <div className="w-[60%] relative">
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  isMulti
                  isSearchable
                  noOptionsMessage={() => "No employee found"}
                  placeholder={"Select Employee"}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 0,
                      // This line disable the blue border
                      boxShadow: "none",

                      height: "100%",
                      // padding: "5px 0px",
                      border: "1px solid #ced4da",
                      borderColor: "gray",
                      borderColor: state.isFocused
                        ? "1px solid orange"
                        : "gray",
                      outline: "orange",

                      borderColor: "#ced4da",
                      "&:hover": {
                        borderColor: "#2387C8",
                      },
                    }),

                    dropdownIndicator: () => ({
                      display: "none",
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),

                    option: (baseStyles) => ({
                      ...baseStyles,
                      //   backgroundColor: "orange",
                      backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#2387C8",
                        color: "#fff",
                      },
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                  }}
                />
                {/* <input
                  type="text"
                  value={employeeName}
                  onChange={(e) => handleEmployeeChange(e)}
                  onFocus={() => {
                    setShowEmployeeList(true);
                  }}
                  onBlur={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                      setShowEmployeeList(false);
                    }, 300);

                    // clearTimeout(timer);
                  }}
                  placeholder="Employee Name"
                  //
                  className={`border inline-block  py-2 px-2   w-full  placeholder:text-sm rounded-[4px] focus:outline-none focus:border-orange-light`}
                /> */}

                {/* {showEmployeeList && (
                  <div className="w-full absolute top-14 left-0 z-[1] bg-white border-[1px] border-gray-300 border-t-0 min-h-[30px] max-h-[200px] rounded-b-md overflow-y-auto">
                    {allUser.length ? (
                      allUser.map((employee, index) => {
                        return (
                          <p
                            key={index}
                            className="px-3 text-sm py-1 hover:bg-orange-light hover:text-white "
                            onClick={() =>
                              handleEmployeeName(employee.name, employee.mobile)
                            }
                          >
                          
                            {formatName(employee.name)}
                          
                          </p>
                        );
                      })
                    ) : (
                      <h1 className="text-sm text-center py-1">
                        Employee not found
                      </h1>
                    )}
                  </div>
                )} */}

                {/* <div
                  ref={divRef}
                  className={`absolute top-2 left-4  w-[${divWidth}px] `}
                >
                  <p>Koushik</p>
                </div> */}
              </div>

              {/* <input
                  type="text"
                  placeholder="Employee Id"
                  className="border px-4 py-2 placeholder:text-sm rounded-[4px] focus:outline-none focus:border-orange-light"
                /> */}
              {/* 
              <div className=" flex bg-white  border  items-center w-[30%] pl-5 rounded-[4px]">
                <h3 className="text-sm w-[200px]">Select Date</h3>
                <Datepicker
                  selected={selectedDateStart}
                  onChange={(date) => setSelectedDateStart(date)}
                  dateFormat={"yyyy/MM/dd"}
                  placeholderText="YYYY/MM/DD"
                  // showYearDropdown
                  wrapperClassName="datePicker"
                  className="w-36"
                ></Datepicker>

                <Datepicker
                  selected={selectedDateEnd}
                  onChange={(date) => setSelectedDateEnd(date)}
                  dateFormat={"yyyy/MM/dd"}
                  placeholderText="YYYY/MM/DD"
                  // showYearDropdown
                  wrapperClassName="datePicker"
                  className="w-36"
                ></Datepicker>
              </div> */}
              <div className="w-[30%] flex  gap-3">
                <Datepicker
                  // portalId="root-portal"
                  // popperClassName="some-custom-class"
                  popperPlacement="bottom"
                  // popperModifiers={[
                  //   {
                  //     name: "offset",
                  //     options: {
                  //       offset: [5, 10],
                  //     },
                  //   },
                  //   {
                  //     name: "preventOverflow",
                  //     options: {
                  //       rootBoundary: "viewport",
                  //       tether: false,
                  //       altAxis: true,
                  //     },
                  //   },
                  // ]}
                  selected={selectedDateStart}
                  onChange={(date) => setSelectedDateStart(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="From"
                  // showYearDropdown
                  wrapperClassName="datePicker"
                ></Datepicker>

                <Datepicker
                  // portalId="root-portal"
                  // popperClassName="some-custom-class"
                  popperPlacement="bottom"
                  // popperModifiers={[
                  //   {
                  //     name: "offset",
                  //     options: {
                  //       offset: [5, 10],
                  //     },
                  //   },
                  //   {
                  //     name: "preventOverflow",
                  //     options: {
                  //       rootBoundary: "viewport",
                  //       tether: false,
                  //       altAxis: true,
                  //     },
                  //   },
                  // ]}
                  selected={selectedDateEnd}
                  onChange={(date) => setSelectedDateEnd(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="To"
                  // showYearDropdown
                  wrapperClassName="datePicker"
                ></Datepicker>
              </div>
              <div className="flex gap-1 items-center">
                {/* <div className=" flex justify-end   w-[35px] h-[25px]  rounded-[4px]">
                  <img
                    src={submitLogo}
                    alt="submit-logo"
                    className="w-[20px] mx-auto cursor-pointer"
                    onClick={() => handleClick()}
                  />
                </div> */}
                <img
                  src={downloadLogo}
                  title="Download"
                  alt="download-logo"
                  className="w-5 cursor-pointer"
                  // onClick={() => downloadData()}
                  // onClick={downlaodDarTableData}
                  onClick={() => downloadTableData("tableId2")}
                />
              </div>

              {/* </form> */}
            </div>

            {/* employee names */}
            <div
              className={`flex flex-wrap gap-2 px-5 ${
                employeeNames.length ? " border-l-gray-400" : ""
              } px-2 `}
            >
              {employeeNames.length
                ? employeeNames.map((name, index) => {
                    return (
                      <div className="flex items-center">
                        <p key={index} className="text-sm text-green-500 ">
                          {name}
                        </p>
                        <img
                          src={crossIcon}
                          alt="cross-icon"
                          className="w-5 cursor-pointer"
                          onClick={() => handleRemoveName(index)}
                        />
                      </div>
                    );
                  })
                : ""}
            </div>
            <div>
              <div className="flex gap-12 px-4 py-3 ">
                <div className="">
                  <input
                    type="checkbox"
                    name="checkbox"
                    className="mr-2 bg-black"
                    // value={attendance}
                    checked={isattendanceChecked}
                    onChange={(e) => handleAttendanceChange(e)}
                  />
                  <span>Attendance</span>
                </div>

                <div className="">
                  <input
                    checked={isDarChecked}
                    onChange={(e) => handleDarChange(e)}
                    type="checkbox"
                    name="checkbox"
                    className="mr-2"
                  />
                  <span>Visit</span>
                </div>

                <div className="">
                  <input
                    checked={isImprestChecked}
                    onChange={(e) => handleImprestChange(e)}
                    type="checkbox"
                    name="checkbox"
                    className="mr-2"
                  />
                  <span>Odometer</span>
                </div>
                <div className="">
                  <input
                    checked={isLeaveChecked}
                    onChange={(e) => handleLeaveChange(e)}
                    type="checkbox"
                    name="checkbox"
                    className="mr-2"
                  />
                  <span>Leave</span>
                </div>
                <div className="">
                  <input
                    checked={isSampleChecked}
                    onChange={(e) => handleSampleChange(e)}
                    type="checkbox"
                    name="checkbox"
                    className="mr-2"
                  />
                  <span>Sample</span>
                </div>

                <div className="">
                  <input
                    checked={isLedgerChecked}
                    onChange={(e) => handleLedgerChange(e)}
                    type="checkbox"
                    name="checkbox"
                    className="mr-2"
                  />
                  <span>Ledger</span>
                </div>
              </div>
            </div>
          </main>

          {/* Table */}

          {isattendanceChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId1"
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[20%] text-center">
                      Date
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      Emp. Code
                    </th>

                    <th scope="col" className="px-2 py-3 w-[20%] text-center">
                      Name
                    </th>

                    <th scope="col" className="px-2 py-3 w-[20%] text-center">
                      Punch In
                    </th>
                    <th scope="col" className="px-2 py-3 w-[20%] text-center">
                      Punch Out
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      Attendance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {searchEmployeeList.length
                    ? searchEmployeeList.map((employee, index) => {
                        return (
                          <tr
                            key={index}
                            className={` ${
                              index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                            } border-b border-gray-100 text-black`}
                          >
                            <td className="px-2 py-2 text-center">
                              {convertDate(employee.attendancedate)}
                              {/* {employee.attendancedate} */}
                            </td>
                            <td className="px-2 py-2  text-center">
                              {" "}
                              {employee.empcode}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {/* {convertIntoCamelCase(employee.name)} */}
                              {formatName(employee.name)}
                              {/* {employee.name} */}
                            </td>

                            <td className="px-2 py-2  text-center">
                              {convertTime(employee.punchintime) ||
                                "not created"}
                            </td>

                            <td className="px-2 py-2   text-center">
                              {convertTime(employee.punchouttime) ||
                                "not created"}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {employee.punchouttime ? (
                                <img
                                  src={submitLogo}
                                  alt="submit-logo"
                                  className="w-[18px] mx-auto  "
                                  // onClick={() => handleLedgerRequest()}
                                />
                              ) : (
                                <img
                                  src={cancelLogo}
                                  alt="submit-logo"
                                  className="w-4 mx-auto"
                                  // onClick={() => resetLedger()}
                                />
                              )}

                              {/* Hello */}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}

          {/* DAR table 🚀🚀🚀 */}

          {isDarChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId2"
                className={`w-full text-sm text-left text-gray-400 border`}
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      Date
                    </th>
                    <th scope="col" className="px-2 py-3 w-[13%] text-center">
                      Name
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      Punch In
                    </th>
                    <th scope="col" className="px-2 py-3 w-[8%] text-center">
                      Punch Out
                    </th>
                    <th scope="col" className="px-2 py-3 w-[20%] text-center">
                      Address
                    </th>
                    <th scope="col" className="px-2 py-3 w-[9%] text-center">
                      Visit
                    </th>
                    <th scope="col" className="px-2 py-3 w-[9%] text-center">
                      Total Distance
                    </th>
                    <th scope="col" className="px-2 py-3 w-[17%] text-center">
                      Remark
                    </th>
                    <th className="w-[4%]"></th>
                  </tr>
                </thead>
                <tbody className="box-content">
                  {employeeDarList.length
                    ? employeeDarList.map((item, index) => {
                        const isExpanded = currentIndex === index;
                        return (
                          <>
                            <tr
                              key={index}
                              onClick={() => {
                                if (currentIndex === index) {
                                  setCurrentIndex(null);
                                  return;
                                }
                                setCurrentIndex(index);
                                // if (showTableRow === index) {
                                //   setShowTableRow(false);
                                //   return;
                                // }
                                // setShowTableRow(index);
                              }}
                              className={` bg-[#fff] border-b  border-t-gray-400 border-gray-100 text-black cursor-pointer ${
                                isExpanded
                                  ? "border border-b-0 border-blue-500"
                                  : ""
                              }`}
                            >
                              <td className="px-2 py-4 text-center">
                                {convertDate(item.attendancedate)}
                              </td>
                              <td className="px-2 py-4   text-center">
                                {item.name}
                              </td>

                              <td className="px-2 py-4  text-center">
                                {convertTime(item.punchintime)}
                              </td>

                              <td className="px-2 py-4   text-center">
                                {convertTime(item.punchouttime) ||
                                  "not created"}
                              </td>

                              <td className="px-2 py-4">
                                {/* {item.address} */}
                                {item.address &&
                                typeof item.address === "string"
                                  ? item.address
                                      .split("|")
                                      .map((location, index) => {
                                        const parts = location.split(":");
                                        if (parts.length === 2) {
                                          return (
                                            <div key={index}>
                                              <span className="location-label font-semibold">
                                                {parts[0]}:
                                              </span>{" "}
                                              {parts[1]}
                                            </div>
                                          );
                                        }
                                        return (
                                          <div key={index}>{location}</div>
                                        );
                                      })
                                  : item.address}
                              </td>

                              <td className="px-2 py-4   text-center">
                                {item.visits.length}
                              </td>
                              <td className="px-2 py-4   text-center">
                                {convertDistance(item.totaldistance)} KM
                              </td>
                              <td className="px-2 py-4   text-center">
                                Morning Punch in and Punch Out
                              </td>
                              <td className="px-2 py-4   text-center">
                                {currentIndex === index ? (
                                  <img
                                    src={downArrow}
                                    alt="uparrow"
                                    className="w-3"
                                  />
                                ) : (
                                  <img
                                    src={upArrow}
                                    alt="down-arrow"
                                    className="w-3"
                                  />
                                )}
                              </td>
                            </tr>

                            {/* Nested table Data */}

                            {item.visits.length ? (
                              <tr
                                className={`w-full   ${
                                  isExpanded
                                    ? "border-[1px] border-b-2 border-t-0 border-blue-400"
                                    : ""
                                }`}
                              >
                                <td
                                  key={index}
                                  colSpan={9}
                                  className="text-center w-full"
                                >
                                  <table
                                    // style={{ display: "none" }}
                                    className={`w-full text-sm text-left text-gray-400 ${
                                      currentIndex === index
                                        ? "revert"
                                        : "hidden"
                                    }`}
                                  >
                                    <thead className="text-xs text-[#1F1F1F] w-full   bg-[#EFEFEF]">
                                      <tr className="bg-gray-400 text-white w-full">
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[10%] text-center"
                                        >
                                          Dealer/Customer
                                        </th>

                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[10%] text-center"
                                        >
                                          Punch In
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[10%] text-center"
                                        >
                                          Punch Out
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[32%] text-center"
                                        >
                                          Location
                                        </th>
                                        {/* <th
                                          scope="col"
                                          className="px-2 py-3 w-[9%] text-center"
                                        >
                                          # Distance
                                        </th> */}
                                        <th
                                          scope="col"
                                          className="px-2 py-3 w-[28%] text-center"
                                        >
                                          Remark
                                        </th>
                                        {/* <th
                                          scope="col"
                                          className="px-2 py-3 w-[4%] text-center"
                                        ></th> */}
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {item.visits.length
                                        ? item.visits.map((item, index) => {
                                            return (
                                              <tr
                                                key={index}
                                                className="  border-b border-gray-100 text-black w-full"
                                              >
                                                <td className="px-2 py-4  text-center">
                                                  {item.dealer}
                                                </td>

                                                <td className="px-2 py-4  text-center">
                                                  {convertTime(
                                                    item.punchintime
                                                  )}
                                                </td>

                                                <td className="px-2 py-4   text-center">
                                                  {convertTime(
                                                    item.punchouttime
                                                  )}
                                                </td>
                                                <td className="px-2 py-4 ">
                                                  {/* {item.address} */}
                                                  {item.address &&
                                                  typeof item.address ===
                                                    "string"
                                                    ? item.address
                                                        .split("|")
                                                        .map(
                                                          (location, index) => {
                                                            const parts =
                                                              location.split(
                                                                ":"
                                                              );
                                                            if (
                                                              parts.length === 2
                                                            ) {
                                                              return (
                                                                <div
                                                                  key={index}
                                                                >
                                                                  <span className="location-label font-semibold">
                                                                    {parts[0]}:
                                                                  </span>{" "}
                                                                  {parts[1]}
                                                                </div>
                                                              );
                                                            }
                                                            return (
                                                              <div key={index}>
                                                                {location}
                                                              </div>
                                                            );
                                                          }
                                                        )
                                                    : item.address}
                                                </td>
                                                {/* <td className="px-2 py-4   text-center">
                                                  {item.distance || "00"} KM
                                                </td> */}
                                                <td
                                                  colSpan={2}
                                                  className="px-2 py-4  text-center"
                                                >
                                                  {item.remark}
                                                </td>
                                              </tr>
                                            );
                                          })
                                        : ""}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}

          {/* Odometer 🚀🚀🚀 */}

          {isImprestChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId3"
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      Date
                    </th>
                    <th scope="col" className="px-2 py-3 w-[8%] text-center">
                      Emp. Code
                    </th>
                    <th scope="col" className="px-2 py-3 w-[15%] text-center">
                      Name
                    </th>
                    <th scope="col" className="px-2 py-3 w-[5%] text-center">
                      Zone
                    </th>
                    <th scope="col" className="px-2 py-3 w-[8%] text-center">
                      PunchIn Reading
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      PunchIn Remark
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      PunchOut Reading
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      PunchOut Remark
                    </th>
                    <th scope="col" className="px-2 py-3 w-[6%] text-center">
                      Vehicle
                    </th>
                    <th scope="col" className="px-2 py-3 w-[8%] text-center">
                      Total Reading
                    </th>
                    <th scope="col" className="px-2 py-3 w-[10%] text-center">
                      Google Reading
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeOdometerList.length
                    ? employeeOdometerList.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className={` ${
                              index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                            } border-b border-gray-100 text-black`}
                          >
                            <td className="px-2 py-2   text-center">
                              {/* {convertDate(item.created_at)} */}
                              {handleOdometerDate(item.date)}
                            </td>

                            <td className="px-2 py-2   text-center">
                              {item.empcode}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {convertIntoCamelCase(item.name)}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item.zone}
                            </td>
                            <td className="px-2 py-2  text-center flex flex-col justify-center items-center">
                              {/* {convertTime(employee.punchintime) || "null"} */}
                              <a
                                href={config.ipAddress + item.punchinimage}
                                target="blank"
                                className="py-1 text-blue-400 inline-block w-fit "
                              >
                                {item.punchinreading}
                              </a>
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item.punchinremark}
                            </td>

                            <td className="px-2 py-2  text-center flex flex-col justify-center items-center">
                              {/* {convertTime(employee.punchintime) || "null"} */}
                              <a
                                href={
                                  getPunchOutDetails(
                                    item.punchinid,
                                    item.punchoutid,
                                    item.punchoutreading,
                                    item.punchoutimage,
                                    item.punchoutremark
                                  ).punchOutImageUrl !== ""
                                    ? getPunchOutDetails(
                                        item.punchinid,
                                        item.punchoutid,
                                        item.punchoutreading,
                                        item.punchoutimage,
                                        item.punchoutremark
                                      ).punchOutImageUrl
                                    : undefined
                                }
                                target="blank"
                                className="py-1 text-blue-400 inline-block w-fit "
                              >
                                {/* {item.punchoutreading} */}
                                {
                                  getPunchOutDetails(
                                    item.punchinid,
                                    item.punchoutid,
                                    item.punchoutreading,
                                    item.punchoutimage,
                                    item.punchoutremark
                                  ).punchOutValue
                                }
                              </a>
                            </td>

                            <td className="px-2 py-2 text-center">
                              {/* {item.punchoutremark} */}
                              {
                                getPunchOutDetails(
                                  item.punchinid,
                                  item.punchoutid,
                                  item.punchoutreading,
                                  item.punchoutimage,
                                  item.punchoutremark
                                ).punchOutRemarkUpdated
                              }
                            </td>

                            <td className="px-2 py-2 text-center">
                              {item.vehicletype}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {getOdoTotalReading(
                                item.punchinreading,
                                item.punchoutreading
                              ) || "0"}
                            </td>

                            <td className="px-2 py-2   text-center">
                              {/* {convertTime(employee.punchouttime) || "null"} */}
                              {Number(item.apireading).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}

          {/* leave request 🚀🚀🚀 */}

          {isLeaveChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId4"
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      Date
                    </th>

                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      Emp. Code
                    </th>
                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      Name
                    </th>

                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      Remark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeLeaveList.length
                    ? employeeLeaveList.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className={` ${
                              index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                            } border-b border-gray-100 text-black`}
                          >
                            <td className="px-2 py-2 text-center">
                              {convertDate(item.created_at)}
                              {/* 20/5/2023 */}
                              {/* {employee.attendancedate} */}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {item.empcode}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {convertIntoCamelCase(item.name)}
                              {/* {employee.name} */}
                            </td>

                            <td className="px-2 py-2   text-center">
                              {/* {convertTime(employee.punchouttime) || "null"} */}
                              {item.reason}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}

          {/* Sample request 🚀🚀🚀 */}

          {isSampleChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId5"
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[30%] text-center">
                      Date
                    </th>
                    <th scope="col" className="px-2 py-3 w-[30%] text-center">
                      Name
                    </th>

                    <th scope="col" className="px-2 py-3 w-[40%] text-center">
                      Remark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeSampleList.length
                    ? employeeSampleList.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className={` ${
                              index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                            } border-b border-gray-100 text-black`}
                          >
                            <td className="px-2 py-2 text-center">
                              {/* {convertDate(employee.attendancedate)} */}
                              {convertDate(item.created_at)}
                              {/* {employee.attendancedate} */}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {convertIntoCamelCase(item.name)}
                              {/* {employee.name} */}
                            </td>

                            <td className="px-2 py-2   text-center">
                              {/* {convertTime(employee.punchouttime) || "null"} */}
                              {item.reason}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}

          {/* ledger reauest 🚀🚀🚀 */}

          {isLedgerChecked && (
            <div className="my-[19px]  overflow-x-auto  shadow-lg">
              <table
                id="tableId6"
                className="w-full text-sm text-left text-gray-400 "
              >
                <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                  <tr className="bg-gray-500 text-white">
                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      Name
                    </th>
                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      Dealer
                    </th>

                    {/* <th scope="col" className="px-6 py-3">
        CODE
      </th> */}

                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      Form
                    </th>
                    <th scope="col" className="px-2 py-3 w-[25%] text-center">
                      To
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeLedgerList.length
                    ? employeeLedgerList.map((item, index) => {
                        return (
                          <tr
                            // key={index}
                            className={` ${
                              index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                            } border-b border-gray-100 text-black`}
                          >
                            <td className="px-2 py-2   text-center">
                              {convertIntoCamelCase(item.name)}
                              {/* {employee.name} */}
                            </td>
                            <td className="px-2 py-2 text-center">
                              {/* {convertDate(employee.attendancedate)} */}
                              {item.dealername}
                              {/* {employee.attendancedate} */}
                            </td>

                            <td className="px-2 py-2   text-center">
                              {/* {convertTime(employee.punchouttime) || "null"} */}
                              {convertDate(item.datefrom)}
                            </td>
                            <td className="px-2 py-2   text-center">
                              {/* {convertTime(employee.punchouttime) || "null"} */}
                              {convertDate(item.dateto)}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          )}
        </section>
      </div>

      {showLoading && (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          // onClick={() => setIsModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      )}
    </div>
  );
}

export default Report;
