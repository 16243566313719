const EditableTableCell = ({
  empcode,
  isEditing,
  onDoubleClick,
  onChange,
  onKeyDown,
  handleOnBlur,
}) => {
  if (isEditing) {
    return (
      <td className="border-r">
        <input
          type="text"
          value={empcode}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={handleOnBlur}
          autoFocus
          className=" py-1 text-center outline-none border border-gray-300"
        />
      </td>
    );
  } else {
    return (
      <td
        onDoubleClick={onDoubleClick}
        className="text-center select-none border-r"
      >
        {empcode}
      </td>
    );
  }
};

export default EditableTableCell;
