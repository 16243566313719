import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import { useNavigate } from "react-router-dom";

import excelIcon from "../../assets/icon-excel.png";
import crossIcon from "../../assets/cross.svg";
import submitLogo from "../../assets/icon-submit.svg";
import loadingGif from "../../assets/loading2.gif";

import config from "../utils/config";
import axios from "axios";

function Upload() {
  const navigate = useNavigate();

  const [demoList, setDemoList] = useState([
    "Upload files will show here",
    "Upload files will show here",
    "Upload files will show here",
  ]);

  const [demoList2, setDemoList2] = useState([
    "Upload files will show here",
    "Upload files will show here",
    "Upload files will show here",
  ]);

  const [dragActive, setDragActive] = useState(false);
  const [allFiles, setAllFile] = useState([]);
  // const [selectedFile, setSelectedFile] = useState(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showUploadAchivement, setShowUploadAchivement] = useState(false);
  const [showUploadTarget, setShowUploadtarget] = useState(true);

  const [showAchivementHistory, setShowAchivementHistory] = useState(false);

  const [showHistory, setShowHistory] = useState(false);

  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [showTargetHistory, setShowTargetHistory] = useState(false);

  const [uploadHistory, setUploadHistory] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  // message
  const [sucessMessage, setSucessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const uploadFiles = () => {
    alert("File Uploaded.");
  };
  // remove demo item

  const removeDemoItem = (indexOfFiles) => {
    let filteredData = demoList.filter((item, index) => {
      return indexOfFiles !== index;
    });
    setDemoList(filteredData);
  };
  const removeDemoItem2 = (indexOfFiles) => {
    let filteredData = demoList2.filter((item, index) => {
      return indexOfFiles !== index;
    });
    setDemoList2(filteredData);
  };
  // handle drag events achivement
  const handleFileSelect = (event) => {
    console.log("runng");
    const files = event.target.files;
    console.log(files);
    const filteredFiles = Array.from(files).filter(
      (file) => file.name.endsWith(".xlsx") || file.name.endsWith(".csv")
    );
    setSelectedFiles([...selectedFiles, ...filteredFiles]);
  };
  // const handleFileSelect = (event) => {
  //   const file = event.target.files;

  //   setSelectedFiles(file);
  // };

  const handleFileSelect2 = (event) => {
    const files = event.target.files;
    const filteredFiles = Array.from(files).filter(
      (file) => file.name.endsWith(".xlsx") || file.name.endsWith(".csv")
    );
    setSelectedFiles2([...selectedFiles2, ...filteredFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    Array.from(files).map((file) => {
      if (file.name.endsWith(".xlsx") || file.name.endsWith(".csv")) {
        const filteredFiles = Array.from(files).filter((file) => {
          return file.name.endsWith(".xlsx") || file.name.endsWith(".csv");
        });
        setSelectedFiles([...selectedFiles, ...filteredFiles]);
      } else {
        alert("Please Choose only excel files!");
      }
    });
  };

  const handleRemoveFile = (file, indexOfRemoveFile) => {
    const updatedFiles = selectedFiles.filter(
      (f, index) => index !== indexOfRemoveFile
    );
    console.log(updatedFiles);
    setSelectedFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      document.getElementById("file-input1").value = null;
    }
  };

  // handle drag events target

  const handleDragOver2 = (event) => {
    event.preventDefault();
  };

  const handleDrop2 = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    Array.from(files).map((file) => {
      if (file.name.endsWith(".xlsx") || file.name.endsWith(".csv")) {
        const filteredFiles = Array.from(files).filter((file) => {
          return file.name.endsWith(".xlsx") || file.name.endsWith(".csv");
        });
        setSelectedFiles2([...selectedFiles2, ...filteredFiles]);
      } else {
        alert("Please Choose only excel files!");
      }
    });
  };

  const handleRemoveFile2 = (file) => {
    const updatedFiles = selectedFiles2.filter((f) => f !== file);
    setSelectedFiles2(updatedFiles);
    if (updatedFiles.length === 0) {
      document.getElementById("file-input2").value = null;
    }
  };

  const convertDateTime = (dateString) => {
    // const dateString = "2023-05-29T10:33:42";

    if (!dateString) return;

    const dateObj = new Date(dateString);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = dateObj.toLocaleDateString("en-US", options);

    const formattedTime = dateObj.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    // console.log(formattedDate); // Output: May 29, 2023
    // console.log(formattedTime); // Output: 10:33 AM
    return { formattedDate, formattedTime };
  };

  //  download sample file
  // const downlaodAchivementSampleFile = () => {
  //   const link = document.createElement("a");
  //   link.href =
  //     "https://salesappapi.superontechnik.com/static/superonacievementformat.xlsx";
  //   link.download = "achivementSample.xlsx";
  //   link.click();
  // };

  // const downlaodTargetSampleFile = () => {
  //   const link = document.createElement("a");
  //   link.href =
  //     "https://salesappapi.superontechnik.com/static/superontargetsformat.xlsx";
  //   link.download = "achivementSample.xlsx";
  //   link.click();
  // };

  // handle download
  const handleDownload = () => {
    if (showUploadAchivement) {
      const link = document.createElement("a");
      link.href =
        "https://salesappapi.stanvac.com/static/stanvacachievement.xlsx";
      link.download = "achivementSample.xlsx";
      link.click();
    } else {
      const link = document.createElement("a");
      link.href = "https://salesappapi.stanvac.com/static/stanvactarget.xlsx";
      link.download = "targetSample.xlsx";
      link.click();
    }
  };

  // submit achivement files
  const submitAchivementFiles = async () => {
    setShowLoading(true);
    let form = new FormData();
    form.append(`file`, selectedFiles[0]);

    try {
      let response = await axios({
        method: "post",
        url: config.ipAddress + `/upload-achievement`,
        data: form,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      setSucessMessage("File uploaded successfully");
      setShowLoading(false);

      setTimeout(() => {
        setSucessMessage("");
      }, 6000);
      // selectedFiles.length = 0;
    } catch (error) {
      setShowLoading(false);

      setErrorMessage("Something went wrong!");
      setTimeout(() => {
        setErrorMessage("");
      }, 6000);
    }
  };

  // submit target files
  const submitTargetFiles = async () => {
    setShowLoading(true);
    let form = new FormData();
    form.append(`file`, selectedFiles2[0]);

    try {
      let response = await axios({
        method: "post",
        url: config.ipAddress + `/stanvac-upload-target`,
        data: form,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setSucessMessage("File uploaded successfully");
      setShowLoading(false);

      setTimeout(() => {
        setSucessMessage("");
      }, 6000);
      // selectedFiles2.length = 0;
      console.log(response);
    } catch (error) {
      setShowLoading(false);

      setErrorMessage("Something went wrong!");
      setTimeout(() => {
        setErrorMessage("");
      }, 6000);
    }
  };

  const getAchivementHistory = async () => {
    let response = await axios.post(config.ipAddress + `/upload-history`);
    console.log(response?.data?.data);
    setUploadHistory(response?.data?.data);
  };

  const handleUploadAchivement = () => {
    setShowHistory(false);
    setShowUploadtarget(false);
    setShowUploadAchivement(true);
  };

  // handle Upload target
  const handleUploadTarget = () => {
    setShowUploadAchivement(false);
    setShowHistory(false);
    setShowUploadtarget(true);
  };

  // handle history
  const handleHistory = () => {
    setShowUploadAchivement(false);
    setShowUploadtarget(false);
    setShowHistory(true);
  };

  useEffect(() => {
    let isValidate = localStorage.getItem("superonAdmin");
    if (!isValidate) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getAchivementHistory();
  }, []);

  console.log(selectedFiles);

  return (
    <div>
      <div className="bg-[#fafafa] h-full ">
        {/* <div className="w-[18%]">
          <Sidebar />
        </div> */}
        <Navbar />

        <section className="w-full px-10 py-4 bg-[#fafafa] h-[88vh]">
          <main className="flex  flex-col  bg-white px-6 h-full   w-full shadow-6xl rounded-[10px] py-4 border-[1px] border-gray-300 overflow-auto">
            <div className="flex gap-2   bg-white pb-3 border-b border-gray-400">
              {/* <div className="bg-orange-light w-[2.5px] rounded-full"></div> */}

              <div className="w-full flex gap-4 items-center justify-between">
                <div className="flex gap-4 items-center">
                  <h1
                    className={`text-lg cursor-pointer ${
                      showUploadTarget ? "text-blue-light" : "text-black"
                    } `}
                    onClick={() => handleUploadTarget()}
                  >
                    Upload Target
                  </h1>
                  {/* <div className="w-[1px] h-5 bg-gray-400"></div>
                  <h1
                    className={`text-lg cursor-pointer ${
                      showUploadAchivement ? "text-blue-light" : "text-black"
                    } `}
                    onClick={() => handleUploadAchivement()}
                  >
                    Upload Achivement
                  </h1> */}

                  <div className="w-[1.5px] h-5 bg-gray-400"></div>
                  <h1
                    className={`text-lg cursor-pointer ${
                      showHistory ? "text-blue-light" : "text-black"
                    } `}
                    onClick={() => handleHistory()}
                  >
                    History
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-[#2B8123] text-sm">{sucessMessage}</h1>
                  <h1 className="text-[#f00] text-sm">{errorMessage}</h1>
                </div>

                <div className="mr-3">
                  {showHistory ? (
                    ""
                  ) : (
                    <button
                      className="text-blue-light"
                      onClick={handleDownload}
                    >
                      Download Sample file
                    </button>
                  )}
                </div>
              </div>
            </div>

            {showUploadAchivement && (
              <section className="flex flex-col   mt-3">
                {/* Demo files */}

                {!showAchivementHistory && (
                  <div className="  flex flex-col flex-wrap min-h-[200px] overflow-auto">
                    {selectedFiles.length === 0 &&
                      demoList.map((item, index) => {
                        return (
                          <div key={index} className="">
                            <div className="flex items-center gap-4 mt-6">
                              <img
                                src={excelIcon}
                                alt="excel-icon"
                                className="w-10"
                              />
                              <p>{item}</p>
                              <img
                                src={crossIcon}
                                alt="cross-icon"
                                className="w-4 cursor-pointer"
                                onClick={() => removeDemoItem(index)}
                              />
                            </div>
                          </div>
                        );
                      })}

                    {selectedFiles.length > 0
                      ? selectedFiles.map((file, index) => (
                          <div key={index}>
                            <div className="flex items-center gap-4 mt-6">
                              <img
                                src={excelIcon}
                                alt="excel-icon"
                                className="w-10"
                              />
                              <p> {file.name}</p>
                              <img
                                src={crossIcon}
                                alt="cross-icon"
                                className="w-4 cursor-pointer"
                                onClick={() => handleRemoveFile(file, index)}
                              />
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                )}

                {/* Upload field achivement  */}

                <section className="mt-5 ">
                  <div
                    class="flex items-center justify-center w-full "
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <label
                      htmlFor="file-input1"
                      class="flex flex-col items-center justify-center w-full  border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800  hover:bg-gray-100"
                    >
                      <div class="flex flex-col items-center justify-center py-14">
                        <svg
                          aria-hidden="true"
                          class="w-8 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span class=" text-3xl">Drag & Drop files here</span>
                        </p>
                        <p class="text-lg text-gray-500 dark:text-gray-400">
                          xlsx or csv files only
                        </p>
                      </div>
                      <input
                        id="file-input1"
                        type="file"
                        class="hidden"
                        // onChange={handleFileUpload}
                        // onChange={(e) => setAllFile(e.target.files)}
                        // onClick={(e) => handleFileSelect(e)}
                        onChange={(e) => handleFileSelect(e)}
                        // multiple
                        accept=".xlsx, .csv"
                      />
                    </label>
                  </div>
                  <div className="text-right  flex justify-end items-center gap-2 mt-2">
                    <div className="   w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                      <img
                        src={crossIcon}
                        alt="submit-logo"
                        className="w-4 cursor-pointer"
                        // onClick={() => resetImprest()}
                      />
                    </div>
                    <div className=" flex justify-center items-center  w-[35px] h-[25px]  rounded-[4px]">
                      <img
                        src={submitLogo}
                        alt="submit-logo"
                        className="w-[18px] cursor-pointer "
                        onClick={() => submitAchivementFiles()}
                      />
                    </div>
                  </div>
                </section>
              </section>
            )}

            {/* upload history 😒😒😒😒😒 */}
            {showHistory && (
              <div className="  flex  flex-wrap  overflow-auto">
                {uploadHistory.length
                  ? uploadHistory.map((item, index) => {
                      return (
                        <div key={index} className="min-w-[200px]">
                          <div className="flex items-center gap-2 mt-6 mr-5">
                            <img
                              src={excelIcon}
                              alt="excel-icon"
                              className="w-8"
                            />
                            <div className="flex flex-col  text-sm">
                              <p>{item.name}</p>
                              <div className="flex items-center  gap-1 text-[10px]">
                                {convertDateTime(item.created_at).formattedDate}
                                <div className="w-[1px] h-4 bg-gray-400"></div>
                                {convertDateTime(item.created_at).formattedTime}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            )}

            {/* target Upload 🚀🚀🚀🚀🚀🚀 */}
            {showUploadTarget && (
              <section className="flex flex-col  justify-around mt-3">
                {/* Demo files */}

                {showUploadTarget && (
                  <div className="  flex flex-col flex-wrap min-h-[200px] overflow-auto">
                    {selectedFiles2.length === 0 &&
                      demoList2.map((item, index) => {
                        return (
                          <div key={index} className="">
                            <div className="flex items-center gap-4 mt-6">
                              <img
                                src={excelIcon}
                                alt="excel-icon"
                                className="w-10"
                              />
                              <p>{item}</p>
                              <img
                                src={crossIcon}
                                alt="cross-icon"
                                className="w-4 cursor-pointer"
                                onClick={() => removeDemoItem2(index)}
                              />
                            </div>
                          </div>
                        );
                      })}

                    {selectedFiles2.length > 0
                      ? selectedFiles2.map((file, index) => (
                          <div key={index}>
                            <div className="flex items-center gap-4 mt-6">
                              <img
                                src={excelIcon}
                                alt="excel-icon"
                                className="w-10"
                              />
                              <p> {file.name}</p>
                              <img
                                src={crossIcon}
                                alt="cross-icon"
                                className="w-4 cursor-pointer"
                                onClick={() => handleRemoveFile2(file)}
                              />
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                )}

                {/* Upload field  */}

                <section className="mt-5">
                  <div
                    class="flex items-center justify-center w-full "
                    onDragOver={handleDragOver2}
                    onDrop={handleDrop2}
                  >
                    <label
                      htmlFor="file-input2"
                      class="flex flex-col items-center justify-center w-full  border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800  hover:bg-gray-100"
                    >
                      <div class="flex flex-col items-center justify-center py-14">
                        <svg
                          aria-hidden="true"
                          class="w-8 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span class=" text-3xl">Drag & Drop files here</span>
                        </p>
                        <p class="text-lg text-gray-500 dark:text-gray-400">
                          xlsx or csv files only
                        </p>
                      </div>
                      <input
                        id="file-input2"
                        type="file"
                        class="hidden"
                        // onChange={handleFileUpload}
                        // onChange={(e) => setAllFile(e.target.files)}
                        onChange={handleFileSelect2}
                        multiple
                        accept=".xlsx, .csv"
                      />
                    </label>
                  </div>
                  <div className="text-right  flex justify-end items-center gap-2 mt-2">
                    <div className="   w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                      <img
                        src={crossIcon}
                        alt="submit-logo"
                        className="w-4 cursor-pointer"
                        // onClick={() => resetImprest()}
                      />
                    </div>
                    <div className=" flex justify-center items-center  w-[35px] h-[25px]  rounded-[4px]">
                      <img
                        src={submitLogo}
                        alt="submit-logo"
                        className="w-[18px] cursor-pointer "
                        onClick={() => submitTargetFiles()}
                      />
                    </div>
                  </div>
                </section>
              </section>
            )}
          </main>
        </section>
      </div>

      {showLoading && (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          // onClick={() => setIsModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      )}
    </div>
  );
}

export default Upload;
