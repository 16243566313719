import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import Select from "react-select";
import downArrow from "../../assets/down-arrow.svg";
import downloadLogo from "../../assets/downloadIcon.svg";
import axios from "axios";
import config from "../utils/config";
import { saveAs } from "file-saver";

function Target() {
  const [optionsEmployee, setOptionsEmployee] = useState([]);
  const [selectedOptionEmployee, setSelectedOptionEmployee] = useState(null);

  const [optionsMonth, setOtionsMonth] = useState([]);
  const [selectedOptionMonth, setSelectedOptionMonth] = useState(null);

  const [optionsYear, setOtionsYear] = useState([]);
  const [selectedOptionYear, setSelectedOptionYear] = useState(null);

  const [targetList, setTargetList] = useState([]);

  const [allUser, setAllUser] = useState([]);
  const [allUserCopy, setAllUserCopy] = useState([]);

  const [lastUploadDate, setLastUploadDate] = useState("");

  function formatName(name) {
    if (!name) return;
    const words = name.toLowerCase().split(" ");

    const formattedWords = words.map((word) => {
      if (word.length > 1) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word.toUpperCase();
      }
    });

    return formattedWords.join(" ");
  }

  const getAllEmployeeList = useCallback(async () => {
    let adminEmail = localStorage.getItem("superonAdmin");

    let data = {
      email: adminEmail,
    };
    let response = await axios.post(config.ipAddress + `/employees`, data);

    // console.log(response?.data?.data);
    let allEmployeeList = response?.data?.data;

    let uniqueEmployeeNames = new Set(); // Use a Set to store unique names
    let filteredEmployeeList = allEmployeeList.filter((employee) => {
      if (employee.name !== null && !uniqueEmployeeNames.has(employee.name)) {
        uniqueEmployeeNames.add(employee.name);
        return true;
      }
      return false;
    });

    // console.log(filteredEmployeeList);
    setAllUser(filteredEmployeeList);
    setAllUserCopy(filteredEmployeeList);

    let optionsArray = filteredEmployeeList.map((employee) => ({
      value: employee.mobile,
      label: formatName(employee.name),
    }));
    setOptionsEmployee(optionsArray);
  }, []);

  // format time
  function formatDateAndTime(inputDate) {
    const date = new Date(inputDate);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    let hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    if (hour > 12) {
      hour -= 12;
    }

    return `${day} ${month}, ${year}, ${hour}:${minute
      .toString()
      .padStart(2, "0")} ${ampm}`;
  }

  // const inputTimeString = "2023-08-16T16:52:16";
  // const formattedTime = formatTime(inputTimeString);
  // console.log(formattedTime); // Output: "4:52 PM on 16/08/2023"

  // targetList
  const getTargetList = async () => {
    try {
      let response = await axios.post(config.ipAddress + `/targetlisting`);
      console.log(response?.data?.data);
      setTargetList(response?.data?.data?.list);
      let uploadDate = response?.data?.data?.uploaddate;
      console.log(uploadDate);
      setLastUploadDate(formatDateAndTime(uploadDate));
    } catch (error) {}
  };

  // get 12 months
  function getMonthsArray() {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const months = monthNames.map((month, index) => ({
      value: (index + 1).toString(),
      label: month,
    }));

    setOtionsMonth(months);
  }

  // getLast 10 years
  function getLastTenYears() {
    const currentYear = new Date().getFullYear();
    const lastTenYears = Array.from({ length: 10 }, (_, index) => {
      const year = currentYear - index;
      return { value: year.toString(), label: year.toString() };
    });

    setOtionsYear(lastTenYears);
  }

  // downlaod table data 🚀🚀🚀
  function downloadTableData() {
    const xlsx = require("xlsx");

    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.table_to_sheet(
      document.getElementById(`tableId`)
    );
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "EmployeeList.xlsx");
  }

  // search functionality
  const handleSearch = async () => {
    let phNumString = [];
    if (selectedOptionEmployee) {
      for (let i = 0; i < selectedOptionEmployee.length; i++) {
        phNumString.push(selectedOptionEmployee[i].value);
      }
    }
    let data = {
      mobile: phNumString.join(","),
    };
    console.log(data);
    try {
      let response = await axios.post(config.ipAddress + `/searchtarget`, data);
      let allData = response?.data?.data;
      setTargetList(allData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllEmployeeList();
    getTargetList();
    getLastTenYears();
    getMonthsArray();
  }, []);

  useEffect(() => {
    if (selectedOptionEmployee) {
      handleSearch();
    } else {
      getTargetList();
    }
  }, [selectedOptionEmployee]);

  return (
    <div>
      <div className=" bg-[#fafafa]">
        <div className="sticky top-0 left-0 z-[3]">
          <Navbar />
        </div>

        <section className={`w-full bg-[#fff] px-10 pt-4 `}>
          <main
            className={`bg-white w-full shadow-6xl rounded-[10px] py-4 border-[1px] border-gray-300 `}
          >
            <div className="flex justify-between items-center">
              <div
                // onClick={() => setShowEmployeeList(false)}
                className="flex gap-2 mb-2 ml-4 "
              >
                <div className="bg-blue-light w-[2.5px] rounded-full"></div>
                <div className=" ">
                  <h1 className="text-lg font-semibold">Target</h1>
                </div>
              </div>

              <div className="mr-4">
                <span className="text-sm">
                  Last Modified on : {lastUploadDate}
                </span>
              </div>
            </div>
            <div className=" py-4 px-4 w-full flex gap-5 ">
              {/* <form
                action=""
                className="flex gap-x-5 w-full justify-between items-center"
              > */}
              <div className="w-[60%] relative">
                <Select
                  defaultValue={selectedOptionEmployee}
                  onChange={setSelectedOptionEmployee}
                  options={optionsEmployee}
                  isMulti
                  isSearchable
                  noOptionsMessage={() => "No employee found"}
                  placeholder={"Select Employee"}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 0,
                      // This line disable the blue border
                      boxShadow: "none",

                      height: "100%",
                      // padding: "5px 0px",
                      border: "1px solid #ced4da",
                      borderColor: "gray",
                      borderColor: state.isFocused
                        ? "1px solid orange"
                        : "gray",
                      outline: "orange",

                      borderColor: "#ced4da",
                      "&:hover": {
                        borderColor: "#2387C8",
                      },
                    }),

                    // dropdownIndicator: () => ({
                    //   display: "none",
                    // }),
                    // indicatorSeparator: () => ({
                    //   display: "none",
                    // }),

                    option: (baseStyles) => ({
                      ...baseStyles,
                      //   backgroundColor: "orange",
                      backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#2387C8",
                        color: "#fff",
                      },
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                  }}
                />
              </div>

              {/* <div className=" flex  gap-x-5">
                <Select
                  defaultValue={selectedOptionMonth}
                  onChange={setSelectedOptionMonth}
                  options={optionsMonth}
                  isSearchable
                  noOptionsMessage={() => "month not found"}
                  placeholder={"Select Month"}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 0,
                      // This line disable the blue border
                      boxShadow: "none",

                      height: "100%",
                      width: "140px",
                      // padding: "5px 0px",
                      border: "1px solid #ced4da",
                      borderColor: "gray",
                      borderColor: state.isFocused
                        ? "1px solid orange"
                        : "gray",
                      outline: "orange",

                      borderColor: "#ced4da",
                      "&:hover": {
                        borderColor: "#2387C8",
                      },
                    }),

               

                    option: (baseStyles) => ({
                      ...baseStyles,
                      //   backgroundColor: "orange",
                      // backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#2387C8",
                        color: "#fff",
                      },
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                  }}
                />
                <Select
                  defaultValue={selectedOptionYear}
                  onChange={setSelectedOptionYear}
                  options={optionsYear}
                  isSearchable
                  noOptionsMessage={() => "year not found"}
                  placeholder={"Select Year"}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 0,
                      // This line disable the blue border
                      boxShadow: "none",

                      height: "100%",
                      width: "130px",
                      // padding: "5px 0px",
                      border: "1px solid #ced4da",
                      borderColor: "gray",
                      borderColor: state.isFocused
                        ? "1px solid orange"
                        : "gray",
                      outline: "orange",

                      borderColor: "#ced4da",
                      "&:hover": {
                        borderColor: "#2387C8",
                      },
                    }),

              
                    option: (baseStyles) => ({
                      ...baseStyles,
                      //   backgroundColor: "orange",
                      // backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#2387C8",
                        color: "#fff",
                      },
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                  }}
                />
              </div> */}
              <div className="flex gap-1 items-center">
                {/* <div className=" flex justify-end   w-[35px] h-[25px]  rounded-[4px]">
                  <img
                    src={submitLogo}
                    alt="submit-logo"
                    className="w-[20px] mx-auto cursor-pointer"
                    onClick={() => handleClick()}
                  />
                </div> */}
                <img
                  src={downloadLogo}
                  title="Download"
                  alt="download-logo"
                  className="w-5 cursor-pointer"
                  // onClick={() => downloadData()}
                  onClick={downloadTableData}
                  // onClick={() => downloadTableData("tableId2")}
                />
              </div>

              {/* </form> */}
            </div>
          </main>
        </section>

        {/* table */}
        <section className={`w-full bg-[#fff] px-10 pt-4 `}>
          <div className="my-[19px]  overflow-x-auto  shadow-lg">
            <table
              id="tableId"
              className="w-full text-sm text-left text-gray-400 "
            >
              <thead className="text-xs text-[#1F1F1F]   bg-[#EFEFEF]">
                <tr className="bg-gray-500 text-white">
                  <th scope="col" className="px-2 py-3  text-center">
                    Contact
                  </th>
                  <th scope="col" className="px-2 py-3  text-center">
                    Name
                  </th>
                  <th scope="col" className="px-2 py-3 text-center">
                    Target
                  </th>
                  <th scope="col" className="px-2 py-3 text-center">
                    Stanvac Sale
                  </th>

                  <th scope="col" className="px-2 py-3 text-center">
                    Sale %
                  </th>

                  <th scope="col" className="px-2 py-3 text-center">
                    Aerosol Target
                  </th>
                  <th scope="col" className="px-2 py-3 text-center">
                    Aerosol Sale
                  </th>

                  <th scope="col" className="px-2 py-3 text-center">
                    Sale Stanvac + Superon
                  </th>

                  <th scope="col" className="px-2 py-3 text-center">
                    With Stv + Sup Sale %
                  </th>

                  <th scope="col" className="px-2 py-3 text-center">
                    Sup Sale
                  </th>
                  {/* <th scope="col" className="px-2 py-3  text-center">
                    # remarks
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {targetList.length
                  ? targetList.map((user, index) => {
                      return (
                        <tr
                          key={index}
                          className={` ${
                            index % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                          } border-b border-gray-100 text-black`}
                        >
                          <td className="px-2 py-2   text-center">
                            {user?.mobile}
                          </td>
                          <td className="px-2 py-2   text-center">
                            {user?.name}
                          </td>
                          <td className="px-2 py-2   text-center">
                            {Number(user?.target).toFixed(2)}
                          </td>
                          <td className="px-2 py-2   text-center">
                            {Number(user?.stanvacsale).toFixed(2)}
                          </td>
                          <td className="px-2 py-2 text-center">
                            {(Number(user?.salepercentage) * 100).toFixed(2)}%
                          </td>

                          <td className="px-2 py-2 text-center">
                            {Number(user?.aerosoltarget).toLocaleString()}
                          </td>

                          <td className="px-2 py-2 text-center">
                            {Number(user?.aerosolsale).toLocaleString()}
                          </td>

                          <td className="px-2 py-2 text-center">
                            {Number(user?.salestanvacsuperon).toFixed(2)}
                          </td>

                          <td className="px-2 py-2 text-center">
                            {(
                              Number(user?.stanvacsuperonsalepercentage) * 100
                            ).toFixed(2)}
                            %
                          </td>

                          <td className="px-2 py-2 text-center">
                            {Number(user?.superonsale).toFixed(2)}
                          </td>

                          {/* <td className="px-2 py-2   text-center">
                            {user?.remark}
                          </td> */}
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Target;
