import React from "react";
import { useState } from "react";
import Select from "react-select";

function SelectZone({ zoneVlaue, updateUserZone, userMobile, zoneOptions }) {
  //   console.log(zoneVlaue);
  const [isLayerOpen, setIsLayerOpen] = useState(true);
  const [isBorderShowing, setIsBorderShowing] = useState(false);

  return (
    <div className="w-full relative">
      <Select
        value={zoneVlaue}
        // menuPortalTarget={document.body}
        onChange={updateUserZone}
        options={zoneOptions}
        className="custom-select"
        isMulti={false}
        isSearchable={false}
        // noOptionsMessage={() => "No employee found"}
        menuPortalTarget={document.body}
        onBlur={() => setIsBorderShowing(false)}
        onMenuOpen={() => setIsBorderShowing(true)}
        onMenuClose={() => setIsBorderShowing(false)}
        menuPosition="fixed"
        placeholder={""}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 0,
            // This line disable the blue border
            boxShadow: "none",

            height: "100%",
            // padding: "5px 0px",
            border: isBorderShowing ? "1px solid #ced4da" : "",
            borderColor: "",
            // borderColor: state.isFocused ? "1px solid orange" : "gray",
            outline: "orange",
            borderWidth: 0,

            borderColor: "#ced4da",
            "&:hover": {
              borderColor: "#2387C8",
            },
          }),

          dropdownIndicator: () => ({
            display: "none",
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),

          option: (baseStyles) => ({
            ...baseStyles,
            overflowY: "hidden",
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: "14px",
          }),
        }}
      />

      {isLayerOpen && (
        <div
          className="bg-transparent absolute top-0 w-full z-30 h-10"
          onClick={() => {
            setIsLayerOpen(false);
            // setIsBorderShowing(true);
            setTimeout(() => {
              setIsLayerOpen(true);
            }, 200);
          }}
        ></div>
      )}
    </div>
  );
}

export default SelectZone;
