import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import editIcon from "../../assets/editIcon.svg";
import loadingGif from "../../assets/loading2.gif";
import crossIcon from "../../assets/cross.svg";
import submitLogo from "../../assets/icon-submit.svg";
import downloadLogo from "../../assets/downloadIcon.svg";

import { saveAs } from "file-saver";
import Select from "react-select";

import "./select.css";
import axios from "axios";
import config from "../utils/config";
import Navbar from "../navbar/Navbar";
import EditableTableCell from "./EditableTableCell";
import SelectZone from "./SelectZone";
import { useNavigate } from "react-router-dom";

import "./dashboard.css";

function Dashboard() {
  let adminEmail = localStorage.getItem("superonAdmin");

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [userStatus, setUserStatus] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [allUser, setAllUser] = useState([]);
  const [allUserCopy, setAllUserCopy] = useState([]);
  const [downloadList, setDownloadList] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const [editingRow, setEditingRow] = useState(null);
  const [isReportingModalOpen, setIsReportingModalOpen] = useState(false);
  const [addbuttonMessage, setAddButtonMessage] = useState("Submit");
  const [coordinatesOptions, setCoordinatesOption] = useState([]);
  const [selectedOptionCoordinates, setSelectOptionCoordinates] = useState([]);
  const [currentUserHeadDetails, setCurrentUserHeadDetails] = useState([]);

  const [employeeOptions, setEmployeeOptions] = useState([
    { value: "3", label: "Inactive" },
    { value: "1", label: "Pending" },
    { value: "2", label: "Active" },
  ]);

  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState({});

  const handleSubmit = () => {
    setIsModalOpen(false);
    alert("Employee Status Updated");
  };

  // get all employee 🚀🚀🚀
  const getAllEmployee = async () => {
    let data = {
      email: adminEmail,
    };
    try {
      setShowLoading(true);
      let response = await axios.post(config.ipAddress + `/employees`, data);

      console.log(response?.data?.data);

      const allUserData = response?.data?.data;

      allUserData.sort((a, b) => {
        const nameA = (a.name || "").toLowerCase().trim();
        const nameB = (b.name || "").toLowerCase().trim();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setAllUser(allUserData);
      setAllUserCopy(response?.data?.data);
      setDownloadList(response?.data?.data);
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
    }
  };

  // Update employee status
  const updateUserStatus = async (mobile, status) => {
    console.log(mobile, status);

    try {
      setShowLoading(true);

      let response = await axios.get(
        config.ipAddress + `/employee/${mobile}/${status.value}`
      );
      console.log(response);
      getAllEmployee();
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
    }
  };

  // search functionality
  const handleSearch = (value) => {
    if (!value) {
      getAllEmployee();
      return;
    }
    console.log(typeof searchValue);

    let filteredList = allUserCopy.filter((item, index) => {
      // console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());

      if (item.name) {
        if (value.toLocaleLowerCase() === "active") {
          return item.status.toLowerCase().trim().includes("2");
        }
        if (value.toLocaleLowerCase() === "inactive") {
          return item.status.toLowerCase().trim().includes("0");
        }
        if (value.toLocaleLowerCase() === "pending") {
          return item.status.toLowerCase().trim().includes("1");
        }

        return (
          item.name.toLowerCase().trim().includes(value.toLowerCase().trim()) ||
          item.email
            .toLowerCase()
            .trim()
            .includes(value.toLowerCase().trim()) ||
          item.mobile.toLowerCase().trim().includes(value.toLowerCase().trim())
          // item.status
          //   .toLowerCase()
          //   .trim()
          //   .includes(String(searchValue.toLowerCase()))
        );
      }

      // person.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      // person.mobile.includes(searchTerm) ||
      // person.email.toLowerCase().includes(searchTerm.toLowerCase())
    });

    console.log(filteredList);
    setAllUser(filteredList);
    setDownloadList(filteredList);
    if (searchValue.length < 0) {
      getAllEmployee();
    }
  };

  // Download Data 🚀🚀🚀
  const downloadData = () => {
    if (!downloadList.length) return;
    const xlsx = require("xlsx");

    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.json_to_sheet(downloadList.flat(10));
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelFileBuffer = xlsx.write(workbook, {
      type: "buffer",
      bookType: "xlsx",
    });

    const blob = new Blob([excelFileBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "data.xlsx";
    link.click();
  };

  // downlaod table data 🚀🚀🚀
  function downloadTableData() {
    const xlsx = require("xlsx");

    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.table_to_sheet(
      document.getElementById(`tableId`)
    );
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "EmployeeList.xlsx");
  }

  const convertIntoCamelCase = (name) => {
    // let nameArr = name.split(" ");
    // let firstName = nameArr[0];
    // let lastName = nameArr[1];
    if (!name) return;
    console.log(name);
    let formattedName = name.charAt(0) + name.slice(1).toLowerCase();

    return formattedName;
  };

  function formatName(name) {
    if (!name) return;
    const words = name.toLowerCase().split(" ");

    const formattedWords = words.map((word) => {
      if (word.length > 1) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word.toUpperCase();
      }
    });

    return formattedWords.join(" ");
  }

  const sortAllUser = () => {
    let val = allUser.sort((a, b) => {
      const nameA = (a.name || "").toLowerCase();
      const nameB = (b.name || "").toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setAllUser(val);
    console.log(val);
  };

  useEffect(() => {
    let isValidate = adminEmail;
    if (!isValidate) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getAllEmployee();
  }, []);

  //reporting functionality

  // get user reporting list
  const getCoordinators = async () => {
    try {
      let response = await axios.post(config.ipAddress + `/coordinators`);
      console.log(response?.data?.data);
      let allCoordinates = response?.data?.data;
      let filteredList = allCoordinates.map(({ email, name }) => ({
        value: email,
        label: formatName(name),
      }));
      console.log(filteredList);
      setCoordinatesOption(filteredList);
    } catch (error) {
      console.log(error);
    }
  };

  // get current user head details
  const userHeadDetails = async (mobile) => {
    try {
      let response = await axios.get(
        config.ipAddress + `/getuserhead/${mobile}`
      );
      console.log(response?.data?.data);
      setCurrentUserHeadDetails(response?.data?.data);
      let headStrings = response?.data?.data[0]?.heads;
      let headArray = headStrings?.split(",");
      console.log(headArray);
      console.log(coordinatesOptions);
      const filteredOptions = coordinatesOptions.filter((option) =>
        headArray.includes(option.value)
      );

      console.log(filteredOptions);
      setSelectOptionCoordinates(filteredOptions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllEmployee();
    getCoordinators();
  }, []);

  useEffect(() => {
    // Apply or remove 'overflow: hidden' based on the 'lockScroll' state
    if (isReportingModalOpen) {
      document.body.style.overflow = "hidden";
      // window.scrollTo({ top: 0, behavior: "smooth" });
      document.documentElement.scrollTop = 0;
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isReportingModalOpen]);

  useEffect(() => {
    if (selectedOptionCoordinates) {
      console.log(selectedOptionCoordinates);
    }
  }, [selectedOptionCoordinates]);

  const handleAddCoordinates = async (phNum) => {
    setAddButtonMessage("Adding...");
    let emailString = [];
    if (selectedOptionCoordinates) {
      for (let i = 0; i < selectedOptionCoordinates.length; i++) {
        emailString.push(selectedOptionCoordinates[i].value);
      }
    }
    let data = {
      mobile: phNum,
      coordinatorid: emailString.join(","),
    };

    try {
      let response = await axios.post(
        config.ipAddress + `/assigncoordinators`,
        data
      );

      console.log(response);
      setAddButtonMessage("Updated Successfully");
    } catch (error) {
      setAddButtonMessage("error!");

      console.log(error);
    } finally {
      setTimeout(() => {
        setAddButtonMessage("Submit");
      }, 1000);
    }
  };

  // edit emp code functionality 🚀🚀
  const handleEmpCodeChange = (e, index) => {
    const updatedAllUser = [...allUser];
    updatedAllUser[index].empcode = e.target.value;
    setAllUser(updatedAllUser);
  };

  const handleDoubleClick = (index) => {
    setEditingRow(index);
  };

  const handleKeyDown = async (e, index, mobile) => {
    if (e.key === "Enter") {
      setEditingRow(null);

      let updatedEmpCode = allUser[index].empcode;
      let data = {
        mobile: mobile,
        empcode: updatedEmpCode,
      };
      try {
        let response = await axios.post(
          config.ipAddress + `/empcodeupdate`,
          data
        );
        console.log(response);
      } catch (error) {
        console.log(error);
      }
      console.log(
        `Updating empcode: ${allUser[index].empcode} to mobile: ${mobile}`
      );
      // Perform further actions like sending data to the server
    }
  };

  const handleOnBlur = () => {
    setEditingRow(null);
  };

  // Zone functionality
  const [zone, setZone] = useState([]);
  const [zoneVlaue, setZoneValue] = useState(null);
  const getZone = async () => {
    try {
      let response = await axios.post(config.ipAddress + `/allzones`);
      console.log(response?.data?.data);
      let zones = response?.data?.data;

      const initialZoneValues = zones?.map((user) => ({
        value: user.name,
        label: user.name,
      }));
      setZone(initialZoneValues);

      // setZone(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserZone = async (selectedOption, mobile) => {
    // setZone(selectedOption);

    try {
      const response = await axios.post(config.ipAddress + "/empzoneupdate", {
        mobile: mobile,
        zone: selectedOption.value,
      });
      console.log("Zone update response:", response);
      getAllEmployee();
    } catch (error) {
      console.error("Error updating zone:", error);
    }
  };

  //
  const getOptionBackgroundColor = (status) => {
    switch (status) {
      case "1":
        return "gray";
      case "3":
        return "red";
      case "2":
        return "#36f022";
      default:
        return "white";
    }
  };

  useEffect(() => {
    getZone();
  }, []);

  useEffect(() => {
    console.log(zone);
  }, [zone]);

  return (
    <div className="w-full">
      <div className="">
        <div className="sticky top-0 left-0 z-[3]">
          <Navbar />
        </div>
        {/* <div className="w-[18%]">
          <Sidebar />
        </div> */}
        <div className="px-10  w-full min-h-[89vh] pt-4 bg-[#fafafa] overflow-auto ">
          <main className="bg-white w-full  shadow-6xl rounded-[10px] py-4  border-[1px] border-gray-300">
            {/* <h1 className="text-lg font-bold">Employee List</h1> */}

            <div className="flex items-center gap-2  px-2 bg-white w-full">
              <div className="bg-blue-light w-[2.5px] h-7 rounded-full"></div>
              <div className="flex justify-between items-center w-full">
                <h1 className="text-lg font-semibold">Employee List</h1>
                {/* Search  */}

                <div className="flex gap-4 mr-3">
                  <input
                    type="text"
                    placeholder="Search here"
                    className="search  border-b text-[14px] border-gray-300 py-1 px-4   focus:outline-none placeholder:text-[16px] focus:border-blue-light "
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />

                  {/* <div className="    h-[25px]  rounded-[4px] flex items-center justify-center">
                <img
                  src={crossIcon}
                  alt="submit-logo"
                  className="w-4 cursor-pointer"
                  onClick={() => {
                    getAllEmployee();
                    setSearchValue("");
                  }}
                />
              </div>
              <div className=" flex justify-center items-center   h-[25px]  rounded-[4px]">
                <img
                  src={submitLogo}
                  alt="submit-logo"
                  className="w-[18px]  cursor-pointer"
                  type="submit"
                  onClick={() => handleSearch()}
                />
              </div> */}
                  <img
                    src={downloadLogo}
                    title="Download"
                    alt="download-logo"
                    className="w-5 cursor-pointer"
                    onClick={() => downloadTableData()}
                  />
                </div>
              </div>
            </div>

            {/* User Table */}
            <section className="w-full pt-4 bg-white rounded-b-lg ">
              <div className="flex flex-col">
                <div className="overflow-x-auto ">
                  <div className="inline-block w-full ">
                    <div className="overflow-hidden">
                      <table
                        id="tableId"
                        className="w-full  text-center text-xs font-light "
                      >
                        <thead className="border-b font-medium   bg-gray-500 text-white">
                          <tr>
                            <th
                              scope="col"
                              className="border-r  px-6 py-3  w-[7%]"
                            >
                              S. No.
                            </th>
                            <th
                              scope="col"
                              className="border-r  px-6 py-3 w-[10%] "
                            >
                              Emp. Code
                            </th>
                            <th
                              // onDoubleClick={() => sortAllUser()}
                              scope="col"
                              className="border-r  px-6 py-3  "
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="border-r  px-6   w-[15%]"
                            >
                              Zone
                            </th>

                            <th scope="col" className="border-r px-6 py-3 ">
                              Email ID
                            </th>

                            <th
                              scope="col"
                              className="border-r px-6 py-3 w-[10%]"
                            >
                              Reporting
                            </th>

                            <th scope="col" className="border px-6 py-3 ">
                              Mobile
                            </th>
                            <th scope="col" className="px-6 py-3 w-[12%]">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {allUser.length ? (
                            allUser.map((user, index) => {
                              return (
                                <tr key={index} className={`border-b text-sm`}>
                                  <td className="whitespace-nowrap border-r px-6 py-1 font-medium ">
                                    {index + 1}
                                  </td>

                                  <EditableTableCell
                                    empcode={user.empcode}
                                    isEditing={editingRow === index}
                                    onDoubleClick={() =>
                                      handleDoubleClick(index)
                                    }
                                    onChange={(e) =>
                                      handleEmpCodeChange(e, index)
                                    }
                                    onKeyDown={(e) =>
                                      handleKeyDown(e, index, user.mobile)
                                    }
                                    handleOnBlur={handleOnBlur}
                                  />

                                  <td className="whitespace-nowrap border-r px-6 py-1 font-medium ">
                                    {/* {convertIntoCamelCase(user.name)} */}
                                    {formatName(user.name)}
                                    {/* {user.name} */}
                                  </td>
                                  <td className="whitespace-nowrap border-r px-6 py-2 font-medium ">
                                    {/* {user.zone} */}

                                    <SelectZone
                                      zoneVlaue={{
                                        value: user.zone,
                                        label: user.zone,
                                      }}
                                      updateUserZone={(selectedOption) =>
                                        updateUserZone(
                                          selectedOption,
                                          user.mobile
                                        )
                                      }
                                      userMobile={user.mobile}
                                      zoneOptions={zone}
                                    />
                                  </td>

                                  <td className="whitespace-nowrap border-r px-6 py-1   ">
                                    {user.email}
                                  </td>
                                  <td
                                    className="whitespace-nowrap border-r px-6 py-1  cursor-pointer "
                                    onClick={() => {
                                      userHeadDetails(user.mobile);
                                      setIsReportingModalOpen(true);
                                    }}
                                  >
                                    View
                                  </td>

                                  <td className="whitespace-nowrap border-r px-6 py-1 ">
                                    {user.mobile}
                                  </td>
                                  <td className="whitespace-nowrap  px-6 py-1  ">
                                    {/* <select
                                      id=""
                                      className={` ${
                                        user.status === "0" &&
                                        "text-red-500 bg-red-100"
                                      } ${
                                        user.status === "1" &&
                                        "text-gray-500 bg-gray-100"
                                      }  ${
                                        user.status === "2" &&
                                        "text-green-500 bg-green-100"
                                      }  px-3  py-1 rounded-md focus:outline-none hover:cursor-pointer text-center`}
                                      onChange={(e) => {
                                        setIsDisabled(false);
                                        setUserStatus(e.target.value);
                                        updateUserStatus(
                                          user.mobile,
                                          e.target.value
                                        );
                                      }}
                                      value={user.status}
                                      // disabled={isDisabled}
                                    >
                                      <option
                                        value="0"
                                        className="bg-black text-white  inline-block pr-7"
                                      >
                                        Inactive
                                      </option>
                                      <option
                                        value="1"
                                        className="bg-black text-white  inline-block pr-7"
                                      >
                                        Pending
                                      </option>
                                      <option
                                        value="2"
                                        className="bg-black text-white  inline-block pr-7"
                                      >
                                        Active
                                      </option>
                                    </select> */}

                                    <Select
                                      value={employeeOptions[user.status]}
                                      onChange={(selectedOption) =>
                                        updateUserStatus(
                                          user.mobile,
                                          selectedOption
                                        )
                                      }
                                      options={employeeOptions}
                                      className="custom-select"
                                      isMulti={false}
                                      isSearchable={false}
                                      noOptionsMessage={() => "not found"}
                                      placeholder={"Select Status"}
                                      menuPortalTarget={document.body}
                                      // menuPosition={"auto"}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          border: 0,
                                          // This line disable the blue border
                                          boxShadow: "none",

                                          height: "100%",
                                          // padding: "5px 0px",
                                          border: "1px solid #ced4da",
                                          borderColor: "gray",
                                          borderColor: state.isFocused
                                            ? "1px solid orange"
                                            : "gray",

                                          borderColor: "#ced4da",
                                          "&:hover": {
                                            borderColor: "#2387C8",
                                          },
                                          backgroundColor:
                                            getOptionBackgroundColor(
                                              user.status
                                            ),
                                        }),
                                        singleValue: (provided) => ({
                                          ...provided,
                                          color: "white", // Change the text color of the selected value
                                        }),
                                        dropdownIndicator: () => ({
                                          display: "none",
                                        }),
                                        indicatorSeparator: () => ({
                                          display: "none",
                                        }),

                                        option: (baseStyles) => ({
                                          ...baseStyles,
                                          overflowY: "hidden",
                                        }),
                                        placeholder: (baseStyles) => ({
                                          ...baseStyles,
                                          fontSize: "14px",
                                        }),
                                        menu: (provided) => ({
                                          ...provided,
                                          overflowY: "auto", // Hide vertical scrollbar
                                          scrollbarWidth: "0px",
                                          zIndex: 10,
                                        }),
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <h1 className="text-lg">No data</h1>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>

      {/* user modal */}
      {isModalOpen && (
        <section className="absolute  z-20   top-[10%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-200px] shadow-md border-[1px] border-gray-400  rounded-[10px]">
          <div className="w-[400px] h-[440px] bg-white border px-4 py-5 rounded-[10px]">
            <h1 className="text-center font-semibold">Employee Details</h1>
            <hr className="h-[1px] bg-gray-400" />

            <div className="mt-7 ">
              <h1>
                <span>Name : </span>
                <span>Koushik Saha</span>
              </h1>
            </div>

            <div className="mt-4 ">
              <h1>
                <span>Email : </span>
                <span>fe@stantech.ai</span>
              </h1>
            </div>

            <div className="mt-4 ">
              <h1>
                <span>Status : </span>
                <span>Active</span>
              </h1>
            </div>
            <hr className="h-[1px]  mt-3 bg-gray-400" />

            <div className="mt-7">
              <h1 className="text-center font-semibold text-orange-light">
                Modify Employee Status
              </h1>

              <div className="mt-3">
                <label htmlFor="" className="font-medium text-md">
                  Select Employee Status
                </label>
                <br />
                <select
                  name=""
                  id=""
                  className="flex w-full rounded-md border-solid border-[1px] box-border  border-gray-300 mt-1 py-3 px-2 text-sm font-medium overflow-auto   focus:outline-none focus:border-[#FF971A]"
                >
                  <option value="Select Option">Select Option</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>

                <div className="text-center mt-4">
                  <button
                    onClick={() => handleSubmit()}
                    className=" rounded-md w-full py-2 bg-[#FF971A] text-center text-lg font-semibold  text-white "
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {isModalOpen && (
        <div
          onClick={() => setIsModalOpen(false)}
          className="absolute h-screen w-full z-[1] opacity-60 bg-gray-300 top-0 left-0"
        ></div>
      )}

      {showLoading && (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          onClick={() => setIsReportingModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      )}

      {/* user modal */}
      {isReportingModalOpen && (
        <section className="absolute  z-40   top-[15%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-300px]  border-[1px] border-gray-400  rounded-[10px] shadow-6xl">
          <div className="w-[600px]   bg-white border px-4 py-5 rounded-[10px]">
            {/* <h1 className="text-center font-semibold mt-5 mb-4 text-lg">
              Employee Reporting
            </h1> */}
            <div className="my-4">
              <h1 className="text-center">
                <span className="font-semibold text-blue-light">
                  Employee Name :{" "}
                </span>
                <span>{formatName(currentUserHeadDetails[0]?.name)}</span>
              </h1>
            </div>
            {/* <hr className="h-[1px] bg-blue-light" /> */}
            <div className="h-[1px] bg-blue-light"></div>

            {/* <hr className="h-[1px]  mt-3 bg-blue-light" /> */}

            <div className="mt-4">
              {/* <h1 className="text-center font-semibold text-blue-light">
                Add New Reporting Person
              </h1> */}

              <div className="w-full my-5">
                <Select
                  value={selectedOptionCoordinates}
                  onChange={setSelectOptionCoordinates}
                  options={coordinatesOptions}
                  className="custom-select"
                  isMulti
                  isSearchable
                  noOptionsMessage={() => "not found"}
                  placeholder={"Add New Reporting Person"}
                  isClearable={false}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "5px",
                      border: 0,
                      // This line disable the blue border
                      boxShadow: "none",

                      height: "100%",
                      // padding: "5px 0px",
                      border: "1px solid #ced4da",
                      borderColor: "gray",
                      borderColor: state.isFocused
                        ? "1px solid orange"
                        : "gray",
                      outline: "orange",

                      borderColor: "#ced4da",
                      "&:hover": {
                        borderColor: "#2387C8",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "white", // Change the text color of the selected value
                      margin: "8px", // Add padding around the selected option
                    }),

                    dropdownIndicator: () => ({
                      display: "none",
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                    input: (provided) => ({
                      ...provided,
                      padding: "8px",
                    }),
                    option: (baseStyles) => ({
                      ...baseStyles,
                      overflowY: "hidden",
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      overflowY: "auto", // Hide vertical scrollbar
                      scrollbarWidth: "0px",
                    }),
                  }}
                />
              </div>
              <div>
                <button
                  className="bg-blue-light text-white text-lg w-full rounded-md py-2 mt-2  mb-4"
                  onClick={() =>
                    handleAddCoordinates(currentUserHeadDetails[0]?.mobile)
                  }
                >
                  {addbuttonMessage}
                </button>
              </div>
            </div>
          </div>

          <div className="absolute top-5 right-5">
            <img
              src={crossIcon}
              alt="cross"
              className="w-5 cursor-pointer"
              onClick={() => setIsReportingModalOpen(false)}
            />
          </div>
        </section>
      )}

      {isReportingModalOpen && (
        <div
          onClick={() => setIsReportingModalOpen(false)}
          className="absolute h-screen w-full z-30 opacity-60 bg-gray-300 top-0 left-0"
        ></div>
      )}
    </div>
  );
}

export default Dashboard;
